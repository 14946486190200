import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ReactLogo } from "../../assets/svg/social/twitter-1.svg";
import { ReactComponent as ReactLogo1 } from "../../assets/svg/social/facebook-1.svg";
import { ReactComponent as ReactLogo2 } from "../../assets/svg/social/instagram-1.svg";
import { ReactComponent as ReactLogo3 } from "../../assets/svg/social/pinterest-1.svg";
import { ReactComponent as ReactLogo4 } from "../../assets/svg/social/behance-1.svg";

const MintPage = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "December, 31, 2022";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div style={{ paddingLeft: "145px", paddingRight: "145px" }}>
        <div className="metaverse_fn_nftsingle">
          <div className="container small">
            <div className="metaverse_fn_share">
              <h5 className="label">Share:</h5>
              <ul>
                <li>
                  <a href="https://twitter.com">
                    <ReactLogo className="fn__svg replaced-svg" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/Kittymemecoin?mibextid=ZbWKwL">
                    <ReactLogo1 className="fn__svg replaced-svg" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com">
                    <ReactLogo2 className="fn__svg replaced-svg" />
                  </a>
                </li>
                <li>
                  <a href="https://www.pinterest.com">
                    <ReactLogo3 className="fn__svg replaced-svg" />
                  </a>
                </li>
                <li>
                  <a href="https://www.behance.net">
                    <ReactLogo4 className="fn__svg replaced-svg" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="metaverse_fn_breadcrumbs">
              <p>
                <Link to="#">Home</Link>
                <span className="separator">/</span>
                <Link to="collection">Collection</Link>
                <span className="separator">/</span>
                <span className="current">Ernet Sharpey</span>
              </p>
            </div>
            <h3 className="fn__maintitle" data-align="left">
              Ernet Sharpey
            </h3>

            <div className="nft_single_img">
              <div className="item">
                <div className="left__border"></div>
                <div className="right__border"></div>
                <div className="item_in">
                  <img src="assets/img/slider/1.png" alt="" />
                </div>
              </div>
              <div className="view_on">
                <ul>
                  <li>
                    <span>View On:</span>
                  </li>
                  <li>
                    <Link to="#">
                      <img
                        src="assets/svg/opensea.svg"
                        alt=""
                        className="fn__svg replaced-svg"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img
                        src="assets/svg/portal.svg"
                        alt=""
                        className="fn__svg replaced-svg"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="desc">
              <p className="colorp" style={{ color: "black" }}>
                Duis eu lorem ut mauris pulvinar auctor. Maecenas et dapibus
                orci, eleifend euismod justo. Quisque luctus turpis eu tristique
                feugiat. Praesent ac magna feugiat, interdum lacus ac, interdum
                dui. Pellentesque id quam quis enim malesuada rutrum. Orci
                varius natoque penatibus et magnis dis parturient.
              </p>
            </div>
            <div className="metaverse_fn_mintbox">
              <div className="mint_title">
                <span>Public Mint is Live</span>
              </div>
              <div className="mint_end">
                <h4>Ends In:</h4>

                <h3
                  className="metaverse_fn_countdown"
                  data-type="ever"
                  data-date="October 13, 2022 12:30:00"
                  data-days="34"
                  data-hours="10"
                  data-minutes="20"
                  data-seconds="0"
                >
                  {days}d: {hours}h: {minutes}m: {seconds}s
                </h3>
              </div>
              <div className="mint_body">
                <div className="mint_content">
                  <div className="mint_list">
                    <ul>
                      <li>
                        <div className="item">
                          <h4>Price</h4>
                          <h3>2.25 ETH</h3>
                        </div>
                      </li>
                      <li>
                        <div className="item">
                          <h4>Quantity</h4>
                          <div className="qnt">
                            <span className="decrease">-</span>
                            <span className="summ" data-price="2.25">
                              2
                            </span>
                            <span className="increase">+</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="item">
                          <h4>Remaining</h4>
                          <h3>344/999</h3>
                        </div>
                      </li>
                      <li>
                        <div className="item">
                          <h4>Total Price</h4>
                          <h3>
                            <span className="total_price">4.5</span> ETH + GAS
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="mint_desc">
                    <Link
                      to="#"
                      target="_blank"
                      className="metaverse_fn_button"
                    >
                      <span className="text">Mint Now</span>
                    </Link>
                    <p>
                      By clicking “MINT NOW” button, you agree to our{" "}
                      <Link to="#">Terms of Service</Link> and our{" "}
                      <Link to="#">Privacy Policy</Link>.
                    </p>
                  </div>
                </div>
                <div className="mint_checked">
                  <p>
                    <span className="text">Whitelist:</span>
                    <span className="status">
                      Soldout{" "}
                      <span className="icon">
                        <img
                          src="assets/svg/checked.svg"
                          alt=""
                          className="fn__svg replaced-svg"
                        />
                      </span>
                    </span>
                  </p>
                  <p>
                    <span className="text">Presale:</span>
                    <span className="status">
                      Soldout{" "}
                      <span className="icon">
                        <img
                          src="assets/svg/checked.svg"
                          alt=""
                          className="fn__svg replaced-svg"
                        />
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="metaverse_fn_nft_cats">
              <ul>
                <li>
                  <div className="item">
                    <h4 className="parent_category">Type</h4>
                    <h3 className="child_category" title="Human">
                      Human
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <h4 className="parent_category">Special</h4>
                    <h3 className="child_category" title="Sakura">
                      Sakura
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <h4 className="parent_category">Face</h4>
                    <h3 className="child_category" title="Eye Scar">
                      Eye Scar
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <h4 className="parent_category">Eyes</h4>
                    <h3 className="child_category" title="Chill">
                      Chill
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <h4 className="parent_category">Clothing</h4>
                    <h3 className="child_category" title="Light Kimono">
                      Light Kimono
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <h4 className="parent_category">Hair</h4>
                    <h3 className="child_category" title="Brown Long">
                      Brown Long
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <h4 className="parent_category">Mouth</h4>
                    <h3 className="child_category" title="Smile">
                      Smile
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <h4 className="parent_category">Neck</h4>
                    <h3 className="child_category" title="Golden Headphones">
                      Golden Headphones
                    </h3>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="container">
            <section id="similar">
              <div className="container">
                <div className="fn_cs_title">
                  <div className="divider" data-color="2">
                    <div className="line"></div>
                    <div className="middle"></div>
                    <div className="left1"></div>
                    <div className="left2"></div>
                    <div className="right1"></div>
                    <div className="right2"></div>
                  </div>
                  <h3 className="title" data-color="white">
                    Similar Items
                  </h3>
                </div>
              </div>

              <div className="metaverse_fn_drops">
                <ul className="grid">
                  <li>
                    <div className="nft__item">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in">
                        <img src="assets/img/drops/1.jpg" alt="" />
                        <Link to="nft-single" className="full_link"></Link>
                        <div className="title_holder">
                          <h3 className="fn_title">
                            <Link to="nft-single">Ernet Sharpey</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="nft__item">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in">
                        <img src="assets/img/drops/2.jpg" alt="" />
                        <Link to="nft-single" className="full_link"></Link>
                        <div className="title_holder">
                          <h3 className="fn_title">
                            <Link to="nft-single">Riley the Ravage</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="nft__item">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in">
                        <img src="assets/img/drops/3.jpg" alt="" />
                        <Link to="nft-single" className="full_link"></Link>
                        <div className="title_holder">
                          <h3 className="fn_title">
                            <Link to="nft-single">Hissing Ornate</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="nft__item">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in">
                        <img src="assets/img/drops/4.jpg" alt="" />
                        <Link to="nft-single" className="full_link"></Link>
                        <div className="title_holder">
                          <h3 className="fn_title">
                            <Link to="nft-single">Sawyer</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="nft__item">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in">
                        <img src="assets/img/drops/5.jpg" alt="" />
                        <Link to="nft-single" className="full_link"></Link>
                        <div className="title_holder">
                          <h3 className="fn_title">
                            <Link to="nft-single">Bell Tameron</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="nft__item">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in">
                        <img src="assets/img/drops/6.jpg" alt="" />
                        <Link to="nft-single" className="full_link"></Link>
                        <div className="title_holder">
                          <h3 className="fn_title">
                            <Link to="nft-single">Cross Laux</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default MintPage;

import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    
      <div className="metaverse_fn_404">
			<div className="container">
				<div className="message_holder">
					<h3>404</h3>
					<h4 className="fn_animated_text" data-color="var(--mc2)" data-to-color="#fff">Page Not Found</h4>
					<p>Sorry, but the page you are looking for was moved, removed, renamed or might never existed...</p>
					<div className="container-custom">            
						<form>
							<input name="s" type="text" placeholder="Type here..."/>
							<Link href="#" className="metaverse_fn_button">
								<span className="text">Search Now</span>
							</Link>
						</form>
					</div>
				</div>
			</div>
		</div>
    
  )
}

export default Error

import React, { useEffect, useState } from "react";

const ComingSoon = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "December, 31, 2022";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="metaverse_fn_page">
      <div className="metaverse_fn_coming_soon">
        <div className="container">
          <div className="soon_countdown">
            {/* <h3
              className="metaverse_fn_countdown boxed"
              data-type="ever"
              data-date="October 13, 2022 12:30:00"
              data-days="34"
              data-hours="9"
              data-minutes="20"
              data-seconds="10"
            >
              0d: 0h: 0m: 0s
            </h3> */}

            <div className="metaverse_fn_boxed_countdown">
              <ul>
                <li className="days">
                  <div className="item">
                    <div className="left__border"></div>
                    <div className="right__border"></div>
                    <div className="count">
                      <h3>{days}</h3>
                      <span>Days</span>
                    </div>
                  </div>
                </li>
                <li className="hours">
                  <div className="item">
                    <div className="left__border"></div>
                    <div className="right__border">
                      <div className="count">
                        <h3>{hours}</h3>
                        <span>Hours</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="minutes">
                  <div className="item">
                    <div className="left__border"></div>
                    <div className="right__border">
                      <div className="count">
                        <h3>{minutes}</h3>
                        <span>Minutes</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="seconds">
                  <div className="item">
                    <div className="left__border"></div>
                    <div className="right__border">
                      <div className="count">
                        <h3>{seconds}</h3>
                        <span>Seconds</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="soon_title">
            <h3
              className="fn_animated_text"
              data-color="var(--mc2)"
              data-to-color="#fff"
            >
              Public Minting is Coming Soon
            </h3>
            <p>
              Our website is coming soon. We are currently working on our
              website. Please check again within couple days.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;

import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { ReactComponent as ReactLogo } from "../../assets/svg/folded.svg";
import { ReactComponent as ReactLogo1 } from "../../assets/svg/claws.svg";
import { ReactComponent as ReactLogo3 } from "../../assets/svg/play.svg";
import { ReactComponent as ReactLogo4 } from "../../assets/svg/mountain.svg";
import { ReactComponent as ReactLogo5 } from "../../assets/svg/checked.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import parallax from "../../assets/img/parallax/1.jpg";
import imge from "../../assets/img/about/1.png";

import roadmap from "../../assets/img/roadmap/1.png";
import roadmap1 from "../../assets/img/roadmap/1.png";
import roadmap2 from "../../assets/img/roadmap/2.png";
import roadmap3 from "../../assets/img/roadmap/3.png";
import roadmap4 from "../../assets/img/roadmap/4.png";
import roadmap5 from "../../assets/img/roadmap/5.png";
import roadmap6 from "../../assets/img/roadmap/6.png";
import roadmap7 from "../../assets/img/roadmap/7.png";
import roadmap8 from "../../assets/img/roadmap/8.png";
import roadmap9 from "../../assets/img/roadmap/9.png";
import roadmap10 from "../../assets/img/roadmap/10.png";
import team1 from "../../assets/img/team/1.jpg";
import team2 from "../../assets/img/team/2.jpg";
import team3 from "../../assets/img/team/3.jpg";
import team4 from "../../assets/img/team/4.jpg";
import team5 from "../../assets/img/team/5.jpg";
import team6 from "../../assets/img/team/6.jpg";
import blog1 from "../../assets/img/blog/1.jpg";
import blog2 from "../../assets/img/blog/2.jpg";
import blog3 from "../../assets/img/blog/3.jpg";
import blog4 from "../../assets/img/blog/4.jpg";
import sponsor1 from "../../assets/img/sponsor/1.png";
import sponsor2 from "../../assets/img/sponsor/2.png";
import sponsor3 from "../../assets/img/sponsor/3.png";
import sponsor4 from "../../assets/img/sponsor/4.png";
import sponsor5 from "../../assets/img/sponsor/5.png";
import sponsor6 from "../../assets/img/sponsor/6.png";
import sponsor7 from "../../assets/img/sponsor/7.png";
import sponsor8 from "../../assets/img/sponsor/8.png";
import { Waypoint } from "react-waypoint";

import cutekitty from "../../assets/new/catdance.gif";
import twoparallax from "../../assets/new/two-kittens.jpg";
import imge1 from "../../assets/new/kitty-tokenomics.png";
import pdfWhitepaper from "../../assets/files/whitepaper.pdf";
import solidProofAudit from "../../assets/files/audit-solidproof.pdf";

import roundLogo from "../../assets/new/logo.png";
import prosperityLogo from "../../assets/new/prosperity.jpeg";
import healerImage from "../../assets/new/Healer.jpg";
import ngoImage from "../../assets/new/Charity.jpg";
import practicalImage from "../../assets/new/Education.jpg";
import angelImage from "../../assets/new/practical-education.png";
import roadImage from "../../assets/new/roadmap-image.jpeg";
import logoShieldSloganWhite from "../../assets/img/logo_shield_text_white.svg";
import logoCoinsult from "../../assets/img/audit_by_coinsult.png";
import logoCoinsultPartner from "../../assets/img/coinsult_full.png";
import logoLBank from "../../assets/img/lbank.png";
import logoPinkSale from "../../assets/img/pinksale.png";
import logoDexView from "../../assets/img/dexview.png";


import { useEffect } from "react";
const Home = () => {
  const swiperRef = useRef(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [massage, setMassage] = useState("");
  const [tel, setTel] = useState("");
  const [subject, setSubjet] = useState("");
  const [faqleft, setfaqleft] = useState(0);
  const [faqright, setfaqright] = useState({});
  const [notification, setNotification] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);

  const sendMailRef = useRef();

  const faq1 = [
    {
      id: 2,
      question: "How do i buy $KMC ?",
      ans: 'Ready to be a Skitter? Visit this link <a href="https://www.pancakeswap.finance/swap?outputCurrency=0x47C9dAE1246ec7bf1738e4E72F404A4E3B1EbecC" target="_blank" rel="noopener noreferrer">here</a>.',
    },
    {
      id: 3,
      question: "What is a Fairlaunch ?",
      ans: "A fair launch means that the distribution of tokens or coins happens without any pre-allocated amounts or privileged sales. It ensures that every individual has an equal chance to acquire the new cryptocurrency at the same price, ensuring fairness and avoiding price discrepancies.",
    },
  ];

  
  const faq2 = [
    {
      id: 4,
      question: "What is the benefit of a Fairlaunch ?",
      ans: "High Profits & Mitigated Risk of Low Adoption By leveling the playing field and preventing undue control by individuals or groups, fair launches increase the likelihood of widespread acceptance and adoption, reducing the risk of low engagement and project failure.",
    },
    {
      id: 5,
      question: "is it Safe ?",
      ans: "First Meme coin to Pass  Two Audits by Tier 1 Auditing agencies Solidproof & Coinsult even before the launch",
    },
    // {
    //   id: 7,
    //   question: "HOW DO I STAY UPDATED AND SAFE?",
    //   ans: "A handcrafted collection of 10,000 characters developed by artist Frenify. In its purest form, RENGA is art and the art of storytelling.",
    // },
    // {
    //   id: 8,
    //   question: "WHAT IS META LEGENDS?",
    //   ans: "A handcrafted collection of 10,000 characters developed by artist Frenify. In its purest form, RENGA is art and the art of storytelling.",
    // },
  ];

  const handleSumbit = (e) => {
    const url = "https://mediacity.co.in/metaverse/assets/modal/contact.php";
    let fData = new FormData();
    fData.append("name", name);
    fData.append("email", email);
    fData.append("massage", massage);
    fData.append("tel", tel);
    fData.append("subject", subject);

    axios
      .post(url, fData)
      .then((response) => {
        setName("");
        setEmail("");
        setMassage("");
        setTel("");
        setSubjet("");
        setNotification(true);
      })
      .catch((error) => {});
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_71ot0ja",
        "template_z0p6fbm",
        e.target,
        "d62IZ3A6ZzpWQ6QO7"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmailSuccess(true);
          sendMailRef.current.reset();
        },
        (error) => {
          console.log(error.text);
          setEmailSuccess(false);
        }
      );
  };

  function faq1Update(item_id) {
    setfaqleft(item_id);
  }

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification(false);
      }, 3000);
    }
  }, [notification]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const f1 = useRef();
  const f2 = useRef();
  const f3 = useRef();
  const f4 = useRef();
  const f5 = useRef();
  const f6 = useRef();
  const f7 = useRef();
  const f8 = useRef();
  const f9 = useRef();
  const f10 = useRef();
  const f11 = useRef();
  const f12 = useRef();
  const f13 = useRef();
  const f14 = useRef();
  const f15 = useRef();
  const f16 = useRef();
  const f17 = useRef();
  const f18 = useRef();
  const f19 = useRef();
  const f20 = useRef();
  const f21 = useRef();

  const elmn = (elnum) => {
    let body = "body";
    let element;
    if (elnum === 1) {
      element = f1.current;
    } else if (elnum === 2) {
      element = f2.current;
    } else if (elnum === 3) {
      element = f3.current;
    } else if (elnum === 4) {
      element = f4.current;
    } else if (elnum === 5) {
      element = f5.current;
    } else if (elnum === 6) {
      element = f6.current;
    } else if (elnum === 7) {
      element = f7.current;
    } else if (elnum === 8) {
      element = f8.current;
    } else if (elnum === 9) {
      element = f9.current;
    } else if (elnum === 10) {
      element = f10.current;
    } else if (elnum === 11) {
      element = f11.current;
    } else if (elnum === 12) {
      element = f12.current;
    } else if (elnum === 13) {
      element = f13.current;
    } else if (elnum === 14) {
      element = f14.current;
    } else if (elnum === 15) {
      element = f15.current;
    } else if (elnum === 16) {
      element = f16.current;
    } else if (elnum === 17) {
      element = f17.current;
    } else if (elnum === 18) {
      element = f18.current;
    } else if (elnum === 19) {
      element = f19.current;
    } else if (elnum === 20) {
      element = f20.current;
    } else if (elnum === 21) {
      element = f21.current;
    }
    let words = element.innerHTML.split(" ");
    let speed = 0.03; // 0.05

    let color = "#fff";
    let toColor = "var(--mc2)";

    let escapedColor = color.replace(/[^a-zA-Z0-9]/g, "_");

    let word = "";
    let rate = 0;
    if (!element.classList.contains("stop")) {
      element.classList.add("stop");
      element.innerHTML = "";
      var html = "";
      for (let i = 0; i < words.length; i++) {
        word = "";
        let newword = words[i].split("");
        for (let j = 0; j < newword.length; j++) {
          word +=
            '<span class="letter" style="animation: FrenifyLetterAnimation_tc' +
            " .5s " +
            rate * speed +
            's  forwards">' +
            newword[j] +
            "</span>";
          rate++;
        }
        html += '<span class="word">' + word + "</span>&nbsp";
      }

      const template = document.createElement("style");
      template.innerHTML =
        "<style>@keyframes FrenifyLetterAnimation_tc" +
        " { 60% {transform: translate(20px, 0) scale(1); color: " +
        color +
        ";} 80% {transform: translate(20px, 0) scale(1); color: " +
        color +
        ";} 99% {transform: translate(0) scale(1.2);color: " +
        toColor +
        ";} 100% {transform: translate(0) scale(1); opacity: 1; color: " +
        color +
        ';} }.fn_animated_text[data-color="' +
        color +
        '"],.desc[data-color="' +
        color +
        '"],.fn_animated_text[data-color="' +
        color +
        '"] .letter{color: ' +
        color +
        ";}</style>";

      document.body.appendChild(template);
      setTimeout(function () {
        element.classList.add("done");
      }, 500 + rate * speed * 1000);
      element.innerHTML = html;
    }
  };

  const handleCircleClick = (slideIndex) => {
    swiperRef.current.swiper.slideTo(slideIndex);
  };

  return (
    <>
      <section id="about">
        <div className="container">
          <div className="fn_cs_title ready">
            <div className="divider" data-color="1">
              <div className="line"></div>
              <div className="middle"></div>
              <div className="left1"></div>
              <div className="left2"></div>
              <div className="right1"></div>
              <div className="right2"></div>
            </div>
            <Waypoint onEnter={() => elmn(15)}>
              <h3
                className="fn_animated_text fn_animated_textblack title"
                data-color="black"
                ref={f15}
                style={{ justifyContent: "center" }}
              >
                Discover the Inspiration Behind the Coin
              </h3>
            </Waypoint>
          </div>
        </div>

        <div className="container">
          <div className="fn_row">
            <div className="fn_col2">
              <p
                className="fn_animated_text small "
                data-color="black"
                style={{ color: "black", fontSize: "23px" }}
              >
                Cats have been referenced in ancient cultures as symbols of
                luck, prosperity, and protection. In Hindu mythology, the
                goddess Durga was gifted with a tiger as her vehicle and
                protector. The Japanese maneki-neko figurine brings good fortune
                to its owner. Scottish lore says that a cat entering a house
                will bring prosperity.
              </p>
            </div>
            <div className="fn_col2">
              <p
                className="fn_animated_text small"
                style={{ color: "black", fontSize: "23px" }}
                data-color="black"
              >
                Among British sailors, cats on a ship were believed to bring
                fair weather on a voyage. And let's not forget the famous
                Internet cats that have captured our hearts and screens with
                their adorable antics. Kitty Meme Coin combines this rich
                history with cutting-edge technology to bring you a coin that
                not only has value but also spreads positivity and goodwill.
              </p>
            </div>
          </div>
        </div>

        <div className="about_img">
          <img src={cutekitty} alt="" height="200px" />
          <ReactLogo alt="" className="fn__svg bottom replaced-svg" />
          <ReactLogo1 alt="" className="fn__svg top replaced-svg" />
        </div>
      </section>

      <section id="prosperity">
        <div className="mypadone">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <Waypoint onEnter={() => elmn(6)}>
                  <h2
                    className="fn_animated_text chiller font-70"
                    data-color="black"
                    ref={f6}
                    style={{ justifyContent: "" }}
                  >
                    Attract Prosperity and Good Fortune
                  </h2>
                </Waypoint>
                {/* <h2 className="chiller font-70">It Brings Prosperity (Goodluck Coin)</h2> */}
                <p
                  style={{
                    color: "#c8dfff",
                    fontSize: "25px",
                    // paddingLeft: "70px",
                  }}
                >
                  Kitty symbolizes wealth and happiness, grounded in folklore
                  from cultures worldwide. It's a token filled with the promise
                  of prosperity, love, and good luck. Abundance and good fortune
                  are the spirit of Kitty Meme Coin. With it in your digital
                  wallet, you'll have the potential for financial gain along
                  with the chance to spread good vibes and positivity wherever
                  you go. Kitty is your good luck charm in the digital world.
                </p>
                {/*<h2 className="chiller font-45 mt-4"><q>Cats know how to obtain food without labour, shelter without confinement, and love without penalties.</q></h2>*/}
                {/* <p style={{ color: "#c8dfff", fontSize: "25px" }}>
                  Scottish Lore believes that a cat entering a house will bring
                  Prosperity. The Welsh community associated cats with Good Luck
                  and Good Health. British and Japanese ladies believed that a
                  cat entering their home would bring many Suitors. In Norse
                  mythology, Freya, the goddess of love, fertility, and beauty,
                  rode in a chariot drawn by cats. Among British sailors, cats
                  on a ship were believed to bring fair weather on a voyage.
                  Therefore kitty is here to bring prosperity, love and good
                  fortune into your life.
                </p> */}
                <br />
              </div>
              <div className="col-md-4">
                <img src={prosperityLogo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="mission">
        <div className="mypadone">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <img src={healerImage} alt="" />
              </div>
              <div className="col-md-8">
                <Waypoint onEnter={() => elmn(3)}>
                  <h2
                    className="fn_animated_text chiller font-70"
                    data-color="black"
                    ref={f3}
                    style={{ justifyContent: "" }}
                  >
                    Help Spread Love, Kindness, and Compassion
                  </h2>
                </Waypoint>
                {/* <h2 className="chiller font-70">A Divine Healer</h2> */}
                <p style={{ color: "#c8dfff", fontSize: "25px" }}>
                  Kitty Meme Coin is more than just a meme – it's a movement for
                  change. With every transaction made using Kitty, a portion
                  goes towards charitable causes that further our mission of
                  spreading love, kindness, and compassion. We want to build a
                  better world, invest in education, create better communities,
                  and empower individuals to make a positive impact. We plan to
                  work with established NGOs, creating a sustainable impact in
                  the world for generations to come.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="education">
        <div className="mypadone">
          <div className="container">
            <Waypoint onEnter={() => elmn(9)}>
              <h2
                className="fn_animated_text fn_animated_textblack chiller font-70"
                data-color="black"
                ref={f9}
                style={{ justifyContent: "" }}
              >
                Educate Yourself on the Heart, Mind, and Body
              </h2>
            </Waypoint>

            <div className="row">
              <div className="col-md-8">
                {/*<p style={{ color: "#000", fontSize: "25px" }}>Kitty is here to create a World full of Joy and Happiness, and it will begin by fighting all the diseases and by helping unprivileged and impoverished people who are sick and are unable to pay for their treatments all over the world. <br/><br/>*/}
                <p style={{ color: "#000", fontSize: "25px" }}>
                  We aim to teach and educate the Kitty community about the
                  importance of self-care, self-love, and healing the mind and
                  body. With educational resources and workshops offered by
                  Kitty, you can gain valuable knowledge on topics such as
                  mental health, physical wellness, and personal growth. We want
                  to reduce the rates of anxiety, depression and other mental
                  health issues by promoting a healthy and positive lifestyle
                  through our platform. We believe that when you educate the
                  individual on self-care, they can use the power of knowledge
                  to transform themselves and the world around them.
                </p>
              </div>
              <div className="col-md-4">
                <img src={practicalImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="ngo">
        <div className="mypadone">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <img src={ngoImage} alt="" />
              </div>
              <div className="col-md-8">
                {/*<Waypoint onEnter={() => elmn(5)}>*/}
                {/*  */}
                {/*</Waypoint>*/}
                <h2
                  className="fn_animated_text title chiller font-70"
                  data-color="#000"
                  ref={f5}
                  style={{ justifyContent: "" }}
                >
                  Nurture Talent for a Brighter Future
                </h2>

                <p style={{ color: "#c8dfff", fontSize: "25px" }}>
                  Kitty seeks out brilliant minds with groundbreaking ideas. We
                  are constantly searching for new and innovative ways to make a
                  positive change in the world, and we want to provide the
                  resources and opportunities for these ideas to thrive. That's
                  why we aim to provide the financial support people need to
                  bring those visions to life and push humanity forward, one
                  innovation at a time. We believe that by supporting and
                  nurturing talent, we are investing in a brighter future for
                  all.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="talent">
        <div className="mypadone">
          <div className="container">
            <Waypoint onEnter={() => elmn(11)}>
              <h2
                className="fn_animated_text fn_animated_textblack chiller font-70"
                data-color="black"
                ref={f11}
                style={{ justifyContent: "" }}
              >
                Join the Movement
              </h2>
            </Waypoint>

            <div className="row">
              <div className="col-md-8">
                <p style={{ color: "#000", fontSize: "25px" }}>
                  We invite you to join us on our journey towards creating a
                  better world. Whether you are an investor, entrepreneur,
                  activist, or simply someone looking for ways to make a
                  difference, there is a place for you in our movement.
                  Together, we can create positive change and build a brighter
                  future for all.
                </p>
              </div>
              <div className="col-md-4">
                <img src={angelImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>



      {/*<section id="video">*/}
      {/*  <div className="container">*/}
      {/*    <div className="fn_cs_title ready">*/}
      {/*      <div className="divider" data-color="2">*/}
      {/*        <div className="line"></div>*/}
      {/*        <div className="middle"></div>*/}
      {/*        <div className="left1"></div>*/}
      {/*        <div className="left2"></div>*/}
      {/*        <div className="right1"></div>*/}
      {/*        <div className="right2"></div>*/}
      {/*      </div>*/}
      {/*      <h3 className="title small" data-color="white">*/}
      {/*        Watch Our trailer on <span>youtube</span>*/}
      {/*      </h3>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="container">*/}
      {/*    <div className="fn_cs_video">*/}
      {/*      <img src={`${imge1}`} alt="" style={{ marginLeft: "50px" }} />*/}
      {/*      <Link*/}
      {/*        className="popup-youtube"*/}
      {/*        to="https://www.youtube.com/watch?v=7e90gBu4pas"*/}
      {/*      >*/}
      {/*        <ReactLogo3 className="fn__svg replaced-svg" />*/}
      {/*      </Link>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      {/* <section id="collection">
        <div className="container">
          <div className="fn_cs_title ready">
            <div className="divider" data-color="2">
              <div className="line"></div>
              <div className="middle"></div>
              <div className="left1"></div>
              <div className="left2"></div>
              <div className="right1"></div>
              <div className="right2"></div>
            </div>
            <h3 className="title" data-color="white">
              From Collection
            </h3>
          </div>
        </div>

        <div className="fn_cs_moving_collection">
          <OwlCarousel
            className={"owl-carousel"}
            loop={true}
            margin={30}
            nav={false}
            dots={false}
            autoWidth={true}
            items={4}
            autoplay={true}
            autoplayTimeout={3000}
            smartSpeed={4000}
            slideTransition={"linear"}
          >
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg2})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg3})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg4})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg5})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg6})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg7})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg8})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg9})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg10})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
          </OwlCarousel>
        </div>

        <div className="fn_cs_moving_collection">
          <OwlCarousel
            className={"owl-carousel"}
            loop={true}
            margin={30}
            nav={false}
            dots={false}
            autoWidth={true}
            items={4}
            autoplay={true}
            autoplayTimeout={3000}
            smartSpeed={4000}
            slideTransition={"linear"}
          >
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg11})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg12})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg13})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg14})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg15})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg16})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg17})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg18})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg19})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg20})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
          </OwlCarousel>
        </div>

        <div className="fn_cs_moving_collection">
          <OwlCarousel
            className={"owl-carousel"}
            loop={true}
            margin={30}
            nav={false}
            dots={false}
            autoWidth={true}
            items={4}
            autoplay={true}
            autoplayTimeout={3000}
            smartSpeed={4000}
            slideTransition={"linear"}
          >
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg22})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg23})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg24})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg25})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg26})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg27})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg28})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg29})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg30})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg31})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
            <div className="item isize">
              <div className="left__border"></div>
              <div className="right__border"></div>
              <div
                className="abs_img sizeing"
                style={{ backgroundImage: `url(${dataimg32})` }}
              ></div>
              <div>
                <img src="assets/img/1x1.jpg" alt="" />
              </div>
            </div>
          </OwlCarousel>
        </div>

        <div className="fn_cs_collection_info">
          <div className="container">
            <div className="info_in">
              <h3 className="count">10,000</h3>
              <h3 className="title">Total Items in Collection</h3>
              <p className="fn_animated_text">
                There are many variations of passages of lorem ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.
              </p>
              <Link to="/collation" className="metaverse_fn_button white">
                <span className="text">See all</span>
              </Link>
            </div>
          </div>
          <ReactLogo1 alt="" className="fn__svg bottom replaced-svg" />
        </div>
      </section> */}

      {/*<section id="parallax">*/}
      {/*  <div*/}
      {/*    className="myparallax"*/}
      {/*    style={{*/}
      {/*      backgroundImage: {twoparallax},*/}
      {/*      opacity: "1",*/}
      {/*      height: "150px"*/}
      {/*    }}*/}
      {/*    data-parallax="yes"*/}
      {/*  ></div>*/}
      {/*  <div className="particle_wrapper"></div>*/}
      {/*</section>*/}



     




      <section id="roadmap">
        <div className="mypadone">
          <div className="container">
            <div className="fn_cs_title ready">
              <div className="divider" data-color="2">
                <div className="line"></div>
                <div className="middle"></div>
                <div className="left1"></div>
                <div className="left2"></div>
                <div className="right1"></div>
                <div className="right2"></div>
              </div>
              <Waypoint onEnter={() => elmn(12)}>
                <h2
                  className="fn_animated_text title"
                  data-color="black"
                  ref={f12}
                  style={{ justifyContent: "center" }}
                >
                  Roadmap
                </h2>
              </Waypoint>

              {/*<p className="desc fn_animated_text">*/}
              {/*  Our mission is simple: We seek the truth and help people*/}
              {/*  understand the world. This mission is rooted in our belief that*/}
              {/*  great journalism has the power to make each reader’s life richer*/}
              {/*  and more fulfilling, and all of society stronger and more just.{" "}*/}
              {/*</p>*/}
            </div>
          </div>
          <div className="fn_cs_roadmap">
            <div className="container">
              <div className="roadmap_top">
                <Swiper
                  style={{ overflow: "hidden" }}
                  loop
                  breakpoints={{
                    768: {
                      slidesPerView: 6,
                      spaceBetween: 30,
                    },
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 5,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                >
                  <div className="swiper-container">
                    <div className="swiper-wrapper">
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="item odd">
                            <h4>Phase 1</h4>
                            <h3></h3>
                            {/*<h3>2023</h3>*/}
                            <span
                              className="circle"
                              onClick={() => handleCircleClick(1)}
                            ></span>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="item even">
                            <h4>Phase 2</h4>
                            <h3></h3>
                            {/*<h3>2023</h3>*/}
                            <span
                              onClick={() => handleCircleClick(2)}
                              className="circle"
                            ></span>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="item odd">
                            <h4>Phase 3</h4>
                            <h3></h3>
                            {/*<h3>2023</h3>*/}
                            <span
                              onClick={() => handleCircleClick(3)}
                              className="circle"
                            ></span>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="item even">
                            <h4>Phase 4</h4>
                            <h3></h3>
                            {/*<h3>2023</h3>*/}
                            <span
                              onClick={() => handleCircleClick(4)}
                              className="circle"
                            ></span>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="item odd">
                            <h4>Phase 5</h4>
                            <h3></h3>
                            {/*<h3>2024</h3>*/}
                            <span
                              onClick={() => handleCircleClick(5)}
                              className="circle"
                            ></span>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="item even">
                            <h4>Phase 6</h4>
                            <h3></h3>
                            {/*<h3>2024</h3>*/}
                            <span
                              onClick={() => handleCircleClick(6)}
                              className="circle"
                            ></span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>
                  </div>
                </Swiper>
                <div className="roadmap_bg"></div>
              </div>

              <div className="roadmap_bottom">
                <Swiper
                  ref={swiperRef}
                  className=""
                  style={{ overflow: "hidden" }}
                  loop
                  slidesPerView={1}
                  spaceBetween={30}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                >
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="item">
                        <div className="left__border"></div>
                        <div className="right__border"></div>
                        <div className="item_in">
                          <div className="phase">
                            <h3>P 1</h3>
                          </div>
                          <div className="item_content">
                            <div className="info_desc">
                              <ul>
                                <li>Conceptualize the purr-fect idea</li>
                                <li>Assemble the "Purr-fect" Team</li>
                                <li>Comprehensive Branding</li>
                              </ul>
                              <div className="read"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="item">
                        <div className="left__border"></div>
                        <div className="right__border"></div>
                        <div className="item_in">
                          <div className="phase">
                            <h3>P 2</h3>
                          </div>
                          <div className="item_content">
                            <div className="info_desc">
                              <ul>
                                <li>White Paw-er Paper Pursuit</li>
                                <li>White Paper Crafting</li>
                                <li>Smart Contract Development</li>
                                <li>
                                  Website Launch for the Kitties out there
                                </li>
                              </ul>
                              <div className="read"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="item">
                        <div className="left__border"></div>
                        <div className="right__border"></div>
                        <div className="item_in">
                          <div className="phase">
                            <h3>P 3</h3>
                          </div>
                          <div className="item_content">
                            <div className="info_desc">
                              <ul>
                                <li>Social media activation</li>
                                <li>Deployment of Smart Contract on Mainnet</li>
                                <li>Smart Contract Security Audit</li>
                              </ul>
                              <div className="read"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="item">
                        <div className="left__border"></div>
                        <div className="right__border"></div>
                        <div className="item_in">
                          <div className="phase">
                            <h3>P 4</h3>
                          </div>
                          <div className="item_content">
                            <div className="info_desc">
                              <ul>
                                <li>Claw-some Community Building</li>
                                <li>Listing on pink sale</li>
                                <li>Listing on DEX</li>
                              </ul>
                              <div className="read"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="item">
                        <div className="left__border"></div>
                        <div className="right__border"></div>
                        <div className="item_in">
                          <div className="phase">
                            <h3>P5</h3>
                          </div>
                          <div className="item_content">
                            <div className="info_desc">
                              <ul>
                                <li>Celebrate 10,000 Kitty holders wallets</li>
                                <li>Listing on CEX's</li>
                                <li>
                                  Collaborate with others in the crypto jungle
                                </li>
                              </ul>
                              <div className="read"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="item">
                        <div className="left__border"></div>
                        <div className="right__border"></div>
                        <div className="item_in">
                          <div className="phase">
                            <h3>P6</h3>
                          </div>
                          <div className="item_content">
                            <div className="info_desc">
                              <ul>
                                <li>VISION MANI-FUR-STATIONS</li>
                              </ul>
                              <div className="read"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          {/*<div className="container">*/}
          {/*  <div className="fn_cs_video">*/}
          {/*    <img src={`${roadImage}`} alt="" style={{marginTop: "50px"}} />*/}

          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </section>

      <section id="video">
        <div className="container">
          <div className="fn_cs_title ready">
            <div className="divider" data-color="2">
              <div className="line"></div>
              <div className="middle"></div>
              <div className="left1"></div>
              <div className="left2"></div>
              <div className="right1"></div>
              <div className="right2"></div>
            </div>
            <Waypoint onEnter={() => elmn(14)}>
              <span
                className="fn_animated_text title small"
                data-color="black"
                ref={f14}
                style={{ justifyContent: "center" }}
              >
                Tokenomics
              </span>
            </Waypoint>
            {/* <h3 className="title small" data-color="white">
              <span>Tokenomics</span>
            </h3> */}
          </div>
        </div>

        <div className="container">
          <div className="fn_cs_video">
            <img src={`${imge1}`} alt="" />
            {/*<Link*/}
            {/*    className="popup-youtube"*/}
            {/*    to="https://www.youtube.com/watch?v=7e90gBu4pas"*/}
            {/*>*/}
            {/*  <ReactLogo3 className="fn__svg replaced-svg" />*/}
            {/*</Link>*/}
          </div>
          {/*<a href={`${pdfWhitepaper}`} className="btn border-white text-white my-3 whitepaperbtn" style={{ borderRadius: "44px", height: "56px", display: "inline-flex", alignItems: "center" }} download>*/}
          {/*  <img*/}
          {/*    src={roundLogo} alt="" className="buy-icon" /> <span className="text-center">Read Whitepaper</span> </a>*/}
          <div className="d-flex flex-row flex-wrap justify-content-center" style={{gap: '25px'}}>
            
           <a
              id="send_message"
              href={`${pdfWhitepaper}`}
              className="metaverse_fn_button full my-3"
            >
              <span className="text">
                <img src={roundLogo} alt="" className="buy-icon" /> Read
                Whitepaper
              </span>
            </a>


            <a
              href={solidProofAudit}
              target="_blank"
            >
              <span className="text">
                <img
                  src={logoShieldSloganWhite}
                  alt=""
                  style={{
                    height: "58px",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                />{" "}
              </span>
            </a>


            <a
              href="https://coinsult.net/projects/kitty-meme-coin/"
              target="_blank"
            >
              <span className="text">
                <img
                  src={logoCoinsult}
                  alt=""
                  style={{
                    height: "58px",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                />{" "}
              </span>
            </a>
          </div>
        </div>
      </section>

      

      <section id="team">
        <div>
          <div className="section_divider">
            <ReactLogo4 alt="" className="fn__svg replaced-svg" />
          </div>
        </div>

        <section style={{marginLeft: '19px'}}>
          <div className="swiper-container">
            <div className="container" style={{ textAlign: 'center' }}>
              <h5>OUR PARTNERS</h5>
            </div>
            <div className="swiper-wrapper mt-4">
              <Swiper ref={swiperRef} style={{ overflow: "hidden" }} loop
                  breakpoints={{
                    768: {
                      slidesPerView: 5,
                      spaceBetween: 2,
                    },
                    0: {
                      slidesPerView: 2,
                      spaceBetween: 5,
                    },
                  }} spaceBetween={30} autoplay={{ delay: 1000, disableOnInteraction: false, }} modules={[Autoplay]}>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item odd">
                      <a href="https://www.dexview.com/" target="_blank"> 
                        <img className="img-fluid" style={{ height: "40px", }} src={logoDexView} alt='dex view' />
                      </a>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item even">
                      <a href="https://www.pinksale.finance" target="_blank">
                          <img className="img-fluid" style={{ height: "40px" }} src={logoPinkSale} alt='pinksale' />
                      </a>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item even">
                      <a href="https://www.lbank.com/" target="_blank">
                        <img className="img-fluid" style={{ height: "30px" }} src={logoLBank} alt='l-bank' />
                      </a>
                    </div>
                  </div>
                  
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item even">
                      <a href="https://solidproof.io/" target="_blank">
                        <img className="img-fluid" alt='solid proof' src={logoShieldSloganWhite} style={{height: "40px",}} />
                      </a>
                    
                    </div>
                  </div>
                  
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item even">
                    <a href="https://coinsult.net/" target="_blank">
                          <img className="img-fluid"  alt='coinsult' src={logoCoinsultPartner} style={{height: "40px",}} />
                       </a>
                    </div>
                  </div>
                    
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
       </section>


        {/* <section id="partnerss mt-5">
        <div className="mypadone">
          <div className="container text-center">
            <h5>OUR PARTNERS</h5>
          </div>
          <div className="d-flex justify-content-around mt-4">
              <img style={{height: "40px",}} src='https://solidproof.io/img/partners/dexview_logo.png' alt='pinsale' />
              <img style={{height: "40px"}} src='https://coinsult.net/wp-content/uploads/2022/02/pinksale-logo-text-white-e1645700608897.png' alt='pinsale' />
              <img style={{height: "40px"}} src='https://strapi.confluxnetwork.org/uploads/img_v2_f281b171_5065_4b3a_81c3_fa3ab4f4b2ah_a073430131.png' alt='pinsale' />
              <img src={logoShieldSloganWhite} style={{height: "40px",}} />
              <img src={logoCoinsult} style={{height: "40px",}} />
          </div>
        </div>
      </section> */}
      </section>

      {/*<section id="team">*/}
      {/*  <div style={{ paddingLeft: "140px", paddingRight: "150px" }}>*/}
      {/*    <div className="container">*/}
      {/*      <div className="fn_cs_title ready">*/}
      {/*        <div className="divider" data-color="2">*/}
      {/*          <div className="line"></div>*/}
      {/*          <div className="middle"></div>*/}
      {/*          <div className="left1"></div>*/}
      {/*          <div className="left2"></div>*/}
      {/*          <div className="right1"></div>*/}
      {/*          <div className="right2"></div>*/}
      {/*        </div>*/}
      {/*        <h3 className="title" data-color="white">*/}
      {/*          Our Core Team*/}
      {/*        </h3>*/}
      {/*        <p className="desc fn_animated_text">*/}
      {/*          Nothing motivates us more than the exceptionally talented people*/}
      {/*          who make up Frenify. We celebrate, but don’t shout about the*/}
      {/*          diversity of our talented team. It is, quite frankly in our DNA;*/}
      {/*          it’s who we are in every fiber of our being. We always seek*/}
      {/*          inspiration to fuel our creative drive and the creative drive of*/}
      {/*          our client partners we create with.*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="container">*/}
      {/*      <div className="fn_cs_team">*/}
      {/*        <ul className="team_list">*/}
      {/*          <li className="team_item">*/}
      {/*            <div className="item">*/}
      {/*              <div className="left__border"></div>*/}
      {/*              <div className="right__border"></div>*/}
      {/*              <div className="item_in" style={{ height: "420px" }}>*/}
      {/*                <div className="image">*/}
      {/*                  <img src="assets/img/1x1.jpg" alt="" />*/}
      {/*                  <div*/}
      {/*                    className="abs_img"*/}
      {/*                    style={{*/}
      {/*                      backgroundImage: `url(${team1})`,*/}
      {/*                    }}*/}
      {/*                  ></div>*/}
      {/*                </div>*/}
      {/*                <div className="title_holder">*/}
      {/*                  <h4 className="occ">*/}
      {/*                    <span>CEO</span>*/}
      {/*                  </h4>*/}
      {/*                  <div className="details">*/}
      {/*                    <h3 className="name">Duncan Shaw</h3>*/}
      {/*                    <ul>*/}
      {/*                      <li>*/}
      {/*                        <Link*/}
      {/*                          to="https://www.facebook.com/"*/}
      {/*                          target="_blank"*/}
      {/*                        >*/}
      {/*                          Fb.*/}
      {/*                        </Link>*/}
      {/*                      </li>*/}
      {/*                      <li>*/}
      {/*                        <Link to="https://twitter.com/" target="_blank">*/}
      {/*                          Tw.*/}
      {/*                        </Link>*/}
      {/*                      </li>*/}
      {/*                      <li>*/}
      {/*                        <Link*/}
      {/*                          to="https://www.behance.net/"*/}
      {/*                          target="_blank"*/}
      {/*                        >*/}
      {/*                          Be.*/}
      {/*                        </Link>*/}
      {/*                      </li>*/}
      {/*                    </ul>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </li>*/}
      {/*    */}
      {/*          <li className="team_item">*/}
      {/*            <div className="item">*/}
      {/*              <div className="left__border"></div>*/}
      {/*              <div className="right__border"></div>*/}
      {/*              <div className="item_in" style={{ height: "420px" }}>*/}
      {/*                <div className="image">*/}
      {/*                  <img src="assets/img/1x1.jpg" alt="" />*/}
      {/*                  <div*/}
      {/*                    className="abs_img"*/}
      {/*                    style={{*/}
      {/*                      backgroundImage: `url(${team2})`,*/}
      {/*                    }}*/}
      {/*                  ></div>*/}
      {/*                </div>*/}
      {/*                <div className="title_holder">*/}
      {/*                  <h4 className="occ">*/}
      {/*                    <span>Senior Designer</span>*/}
      {/*                  </h4>*/}
      {/*                  <div className="details">*/}
      {/*                    <h3 className="name">Greg Hobden</h3>*/}
      {/*                    <ul>*/}
      {/*                      <li>*/}
      {/*                        <Link*/}
      {/*                          to="https://www.facebook.com/"*/}
      {/*                          target="_blank"*/}
      {/*                        >*/}
      {/*                          Fb.*/}
      {/*                        </Link>*/}
      {/*                      </li>*/}
      {/*                      <li>*/}
      {/*                        <Link to="https://twitter.com/" target="_blank">*/}
      {/*                          Tw.*/}
      {/*                        </Link>*/}
      {/*                      </li>*/}
      {/*                      <li>*/}
      {/*                        <Link*/}
      {/*                          to="https://www.behance.net/"*/}
      {/*                          target="_blank"*/}
      {/*                        >*/}
      {/*                          Be.*/}
      {/*                        </Link>*/}
      {/*                      </li>*/}
      {/*                    </ul>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </li>*/}
      {/*    */}
      {/*          <li className="team_item">*/}
      {/*            <div className="item">*/}
      {/*              <div className="left__border"></div>*/}
      {/*              <div className="right__border"></div>*/}
      {/*              <div className="item_in" style={{ height: "420px" }}>*/}
      {/*                <div className="image">*/}
      {/*                  <img src="assets/img/1x1.jpg" alt="" />*/}
      {/*                  <div*/}
      {/*                    className="abs_img"*/}
      {/*                    style={{*/}
      {/*                      backgroundImage: `url(${team3})`,*/}
      {/*                    }}*/}
      {/*                  ></div>*/}
      {/*                </div>*/}
      {/*                <div className="title_holder">*/}
      {/*                  <h4 className="occ">*/}
      {/*                    <span>Lee Arters</span>*/}
      {/*                  </h4>*/}
      {/*                  <div className="details">*/}
      {/*                    <h3 className="name">Greg Hobden</h3>*/}
      {/*                    <ul>*/}
      {/*                      <li>*/}
      {/*                        <Link*/}
      {/*                          to="https://www.facebook.com/"*/}
      {/*                          target="_blank"*/}
      {/*                        >*/}
      {/*                          Fb.*/}
      {/*                        </Link>*/}
      {/*                      </li>*/}
      {/*                      <li>*/}
      {/*                        <Link to="https://twitter.com/" target="_blank">*/}
      {/*                          Tw.*/}
      {/*                        </Link>*/}
      {/*                      </li>*/}
      {/*                      <li>*/}
      {/*                        <Link*/}
      {/*                          to="https://www.behance.net/"*/}
      {/*                          target="_blank"*/}
      {/*                        >*/}
      {/*                          Be.*/}
      {/*                        </Link>*/}
      {/*                      </li>*/}
      {/*                    </ul>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </li>*/}
      {/*    */}
      {/*          */}
      {/*        </ul>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="section_divider">*/}
      {/*      <ReactLogo4 alt="" className="fn__svg replaced-svg" />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      

      <section id="faq">
        <div className="mypadone">
          <div className="container">
            <div className="fn_cs_title ready">
              <div className="divider" data-color="1">
                <div className="line"></div>
                <div className="middle"></div>
                <div className="left1"></div>
                <div className="left2"></div>
                <div className="right1"></div>
                <div className="right2"></div>
              </div>
              <Waypoint onEnter={() => elmn(2)}>
                <h3
                  className="fn_animated_text fn_animated_textblack title"
                  data-color="black"
                  ref={f2}
                  style={{ justifyContent: "center" }}
                >
                  FAQ
                </h3>
              </Waypoint>
              {/* <h3 className="title" data-color="black">
                FAQ
              </h3> */}
            </div>
          </div>

          <div className="container">
            <div className="fn_cs_row">
              <div className="fn_col">
                <div className="fn_cs_accordion">
                  {faq1.map((item) => (
                    <div className={`acc_item`}>
                      <div className="acc_header">
                        <h3 className="fn__maintitle">{item.question}</h3>
                        <span className="icon">
                          <span></span>
                        </span>
                      </div>
                      {/*{item.id === faqleft && (*/}
                      {/*  <div*/}
                      {/*    className="acc_content"*/}
                      {/*    style={{ display: "block" }}*/}
                      {/*  >*/}
                      {/*    <p>{item.ans} </p>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      <div className="acc_content" style={{ display: "none" }}>
                      <p dangerouslySetInnerHTML={{ __html: item.ans }}></p>
                        {/* <p>{item.ans} </p> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="fn_col">
                <div className="fn_cs_accordion">
                  {faq2.map((item) => (
                    <div
                      className={`acc_item ${
                        item.id === faqright ? "active" : ""
                      }`}
                    >
                      <div
                        className="acc_header"
                        onClick={() =>
                          setfaqright(item.id === faqright ? 0 : item.id)
                        }
                      >
                        <h3 className="fn__maintitle">{item.question}</h3>
                        <span className="icon">
                          <span></span>
                        </span>
                      </div>
                      {/*{item.id === faqright && (*/}
                      {/*  <div*/}
                      {/*    className="acc_content"*/}
                      {/*    style={{ display: "block" }}*/}
                      {/*  >*/}
                      {/*    <p>{item.ans} </p>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      <div className="acc_content" style={{ display: "none" }}>
                        <p>{item.ans} </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_icon">
          <ReactLogo alt="" className="fn__svg bottom replaced-svg" />
          <ReactLogo1 alt="" className="fn__svg top replaced-svg" />
        </div>
      </section>

      {/* <section id="blog">
        <div style={{ paddingLeft: "140px", paddingRight: "150px" }}>
          <div className="container">
            <div className="fn_cs_title ready">
              <div className="divider" data-color="2">
                <div className="line"></div>
                <div className="middle"></div>
                <div className="left1"></div>
                <div className="left2"></div>
                <div className="right1"></div>
                <div className="right2"></div>
              </div>
              <h3 className="title" data-color="white">
                Blog Articles
              </h3>
              <p className="desc fn_animated_text">
                This is your chance to take your reader on a journey. What have
                you achieved along the way and what do you envision next?
                Developing a narrative is really quite simple if you follow a
                template, like the one below. As you fill in your template,
                consider the characters and the setting, the problem you solved,
                the obstacles you hit, the milestones you achieve, and what your
                brand's future looks like.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="fn_cs_blog">
              <div className="swiper-container">
                <Swiper
                  loop
                  slidesPerView={2}
                  spaceBetween={30}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                >
                  <div className="swiper-wrapper">
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="blog__item">
                          <div className="left__border"></div>
                          <div className="right__border"></div>
                          <div className="item_in">
                            <div className="image">
                              <Link to="/blogs">
                                <img src={blog1} alt="" />
                              </Link>
                            </div>
                            <div className="item_desc">
                              <div className="meta">
                                <p>August 09, 2022 / MetaVerse / 4 Comments</p>
                              </div>
                              <div className="title">
                                <h3>
                                  <Link to="/blogs">
                                    Reasons Why People Use Discord
                                  </Link>
                                </h3>
                              </div>
                              <div className="desc">
                                <p className="colorp">
                                  There are many variations of passages of lorem
                                  ipsum available, but the majority have
                                  suffered alteration in some form, by injected
                                  humour
                                </p>
                              </div>
                              <div className="more">
                                <Link
                                  to="/blogs"
                                  className="metaverse_fn_button"
                                >
                                  <span className="text">Read More</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="blog__item">
                          <div className="left__border"></div>
                          <div className="right__border"></div>
                          <div className="item_in">
                            <div className="image">
                              <Link to="/blogs">
                                <img src={blog2} alt="" />
                              </Link>
                            </div>
                            <div className="item_desc">
                              <div className="meta">
                                <p>August 09, 2022 / MetaVerse / 4 Comments</p>
                              </div>
                              <div className="title">
                                <h3>
                                  <Link to="/blogs">
                                    Reasons Why People Use Discord
                                  </Link>
                                </h3>
                              </div>
                              <div className="desc">
                                <p className="colorp">
                                  There are many variations of passages of lorem
                                  ipsum available, but the majority have
                                  suffered alteration in some form, by injected
                                  humour
                                </p>
                              </div>
                              <div className="more">
                                <Link
                                  to="/blogs"
                                  className="metaverse_fn_button"
                                >
                                  <span className="text">Read More</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="blog__item">
                          <div className="left__border"></div>
                          <div className="right__border"></div>
                          <div className="item_in">
                            <div className="image">
                              <Link to="/blogs">
                                <img src={blog3} alt="" />
                              </Link>
                            </div>
                            <div className="item_desc">
                              <div className="meta">
                                <p>August 09, 2022 / MetaVerse / 4 Comments</p>
                              </div>
                              <div className="title">
                                <h3>
                                  <Link to="/blogs">
                                    Reasons Why People Use Discord
                                  </Link>
                                </h3>
                              </div>
                              <div className="desc">
                                <p className="colorp">
                                  There are many variations of passages of lorem
                                  ipsum available, but the majority have
                                  suffered alteration in some form, by injected
                                  humour
                                </p>
                              </div>
                              <div className="more">
                                <Link
                                  to="/blogs"
                                  className="metaverse_fn_button"
                                >
                                  <span className="text">Read More</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="blog__item">
                          <div className="left__border"></div>
                          <div className="right__border"></div>
                          <div className="item_in">
                            <div className="image">
                              <Link to="/blogs">
                                <img src={blog4} alt="" />
                              </Link>
                            </div>
                            <div className="item_desc">
                              <div className="meta">
                                <p>August 09, 2022 / MetaVerse / 4 Comments</p>
                              </div>
                              <div className="title">
                                <h3>
                                  <Link to="/blogs">
                                    Reasons Why People Use Discord
                                  </Link>
                                </h3>
                              </div>
                              <div className="desc">
                                <p className="colorp">
                                  There are many variations of passages of lorem
                                  ipsum available, but the majority have
                                  suffered alteration in some form, by injected
                                  humour
                                </p>
                              </div>
                              <div className="more">
                                <Link
                                  to="/blogs"
                                  className="metaverse_fn_button"
                                >
                                  <span className="text">Read More</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </div>
                </Swiper>
              </div>
              <div className="swiper-pagination"></div>
              <div className="read_all">
                <Link to="/blogs">Read All Blog Posts</Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/*<section id="sponsor">*/}
      {/*  <div style={{ paddingLeft: "145px", paddingRight: "145px" }}>*/}
      {/*    <div className="container">*/}
      {/*      <div className="fn_cs_title ready">*/}
      {/*        <div className="divider" data-color="2">*/}
      {/*          <div className="line"></div>*/}
      {/*          <div className="middle"></div>*/}
      {/*          <div className="left1"></div>*/}
      {/*          <div className="left2"></div>*/}
      {/*          <div className="right1"></div>*/}
      {/*          <div className="right2"></div>*/}
      {/*        </div>*/}
      {/*        <h3 className="title" data-color="white">*/}
      {/*          Our Sponsors*/}
      {/*        </h3>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="container">*/}
      {/*      <div className="fn_cs_sponsor">*/}
      {/*        <Swiper*/}
      {/*          className="swiper-container"*/}
      {/*          loop*/}
      {/*          slidesPerView={5}*/}
      {/*          direction="horizontal"*/}
      {/*          loopAdditionalSlides={10}*/}
      {/*          spaceBetween={30}*/}
      {/*          centeredSlides={true}*/}
      {/*          autoplay={{*/}
      {/*            delay: 2500,*/}
      {/*            disableOnInteraction: false,*/}
      {/*          }}*/}
      {/*          modules={[Autoplay]}*/}
      {/*        >*/}
      {/*          <div className="swiper-container">*/}
      {/*            <div className="swiper-wrapper">*/}
      {/*              <SwiperSlide>*/}
      {/*                <div className="swiper-slide">*/}
      {/*                  <div className="item">*/}
      {/*                    <Link to="#" className="full_link"></Link>*/}
      {/*                    <img src={sponsor1} alt="" />*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </SwiperSlide>*/}
      {/*              <SwiperSlide>*/}
      {/*                <div className="swiper-slide">*/}
      {/*                  <div className="item">*/}
      {/*                    <Link to="#" className="full_link"></Link>*/}
      {/*                    <img src={sponsor2} alt="" />*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </SwiperSlide>*/}
      {/*              <SwiperSlide>*/}
      {/*                <div className="swiper-slide">*/}
      {/*                  <div className="item">*/}
      {/*                    <Link to="#" className="full_link"></Link>*/}
      {/*                    <img src={sponsor3} alt="" />*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </SwiperSlide>*/}
      {/*              <SwiperSlide>*/}
      {/*                <div className="swiper-slide">*/}
      {/*                  <div className="item">*/}
      {/*                    <Link to="#" className="full_link"></Link>*/}
      {/*                    <img src={sponsor4} alt="" />*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </SwiperSlide>*/}
      {/*              <SwiperSlide>*/}
      {/*                <div className="swiper-slide">*/}
      {/*                  <div className="item">*/}
      {/*                    <Link to="#" className="full_link"></Link>*/}
      {/*                    <img src={sponsor5} alt="" />*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </SwiperSlide>*/}
      {/*              <SwiperSlide>*/}
      {/*                <div className="swiper-slide">*/}
      {/*                  <div className="item">*/}
      {/*                    <Link to="#" className="full_link"></Link>*/}
      {/*                    <img src={sponsor6} alt="" />*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </SwiperSlide>*/}
      {/*              <SwiperSlide>*/}
      {/*                <div className="swiper-slide">*/}
      {/*                  <div className="item">*/}
      {/*                    <Link to="#" className="full_link"></Link>*/}
      {/*                    <img src={sponsor7} alt="" />*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </SwiperSlide>*/}
      {/*              <SwiperSlide>*/}
      {/*                <div className="swiper-slide">*/}
      {/*                  <div className="item">*/}
      {/*                    <Link to="#" className="full_link"></Link>*/}
      {/*                    <img src={sponsor8} alt="" />*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </SwiperSlide>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </Swiper>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section id="contact">
        <div className="mypadone">
          <div className="container">
            <div className="fn_cs_title ready">
              <div className="divider" data-color="2">
                <div className="line"></div>
                <div className="middle"></div>
                <div className="left1"></div>
                <div className="left2"></div>
                <div className="right1"></div>
                <div className="right2"></div>
              </div>
              <Waypoint onEnter={() => elmn(8)}>
                <h3
                  className="fn_animated_text  title"
                  data-color="black"
                  ref={f8}
                  style={{ justifyContent: "center" }}
                >
                  Contact Us
                </h3>
              </Waypoint>
            </div>
          </div>

          <div className="container">
            <div className="fn_cs_contact_info">
              <ul>
                <li>
                  <div className="item">
                    {/*<h4 className="label">Phone</h4>*/}
                    {/*<h3>*/}
                    {/*  <Link*/}
                    {/*    to="tel:+770221770505"*/}
                    {/*    className="fn_animated_text"*/}
                    {/*    data-color="#fff"*/}
                    {/*  >*/}
                    {/*    +77 022 177 05 05*/}
                    {/*  </Link>*/}
                    {/*</h3>*/}
                    <h4 className="lable">Email</h4>
                    <h3>
                      <a
                        href="mailto:kittymemecoin@gmail.com"
                        className="fn_animated_text"
                        data-color="#fff"
                      >
                        kittymemecoin@gmail.com
                      </a>
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <h4 className="label">Telegram</h4>
                    <h3 className="fn_animated_text" data-color="#fff">
                      <a
                        href="https://t.me/kittymeme_coin"
                        
                        className="fn_animated_text"
                        data-color="#fff"
                        target="_blank"
                      >
                        @kittymeme_coin
                      </a>
                    </h3>
                  </div>
                </li>
              </ul>
            </div>

            {/*<div className="fn_cs_contact_form">*/}
            {/*  {emailSuccess && (*/}
            {/*      <div className="alert alert-primary" role="alert" style={{marginLeft: "30px"}}>*/}
            {/*        Email send successfully!*/}
            {/*      </div>*/}
            {/*  )}*/}

            {/*  <form*/}
            {/*    method="post"*/}
            {/*    className="contact_form"*/}
            {/*    id="contact_form"*/}
            {/*    autoComplete="off"*/}
            {/*    ref={sendMailRef}*/}
            {/*    onSubmit={sendEmail}*/}
            {/*  >*/}
            {/*    <div className="input_list">*/}
            {/*      <ul>*/}
            {/*        <li>*/}
            {/*          <input*/}
            {/*            id="name"*/}
            {/*            type="text"*/}
            {/*            placeholder="Your Name *"*/}
            {/*            name="from_name"*/}
            {/*          />*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <input*/}
            {/*            id="email"*/}
            {/*            type="text"*/}
            {/*            placeholder="Your Email *"*/}
            {/*            name="from_email"*/}
            {/*          />*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <input*/}
            {/*            id="tel"*/}
            {/*            type="text"*/}
            {/*            placeholder="Your Phone (optional)"*/}
            {/*            name="phone"*/}
            {/*          />*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <input*/}
            {/*            id="subject"*/}
            {/*            type="text"*/}
            {/*            placeholder="Topic (optional)"*/}
            {/*            name="subject"*/}
            {/*          />*/}
            {/*        </li>*/}
            {/*        <li className="full">*/}
            {/*          <textarea*/}
            {/*            id="message"*/}
            {/*            placeholder="Your Message *"*/}
            {/*            name="message"></textarea>*/}
            {/*        </li>*/}
            {/*        <li className="full">*/}
            {/*          <label className="fn__checkbox">*/}
            {/*            <input type="checkbox" />*/}
            {/*            <span className="fn__checkmark">*/}
            {/*              <ReactLogo5 className="fn__svg replaced-svg" />*/}
            {/*            </span>*/}
            {/*            <p>*/}
            {/*              I’m okay with getting emails and having that activity*/}
            {/*              tracked to improve my experience.*/}
            {/*            </p>*/}
            {/*          </label>*/}
            {/*          <div>*/}
            {/*            <button*/}
            {/*              id=""*/}
            {/*              type="submit"*/}
            {/*              className="metaverse_fn_button full"*/}
            {/*              style={{background:"transparent"}}*/}
            {/*            >*/}
            {/*              <span className="text">Send Message</span>*/}
            {/*            </button>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*    {notification && (*/}
            {/*      <div*/}
            {/*        className="returnmessage"*/}
            {/*        data-success="Your message has been received, We will contact you soon."*/}
            {/*        style={{ display: "block" }}*/}
            {/*      >*/}
            {/*        <span>*/}
            {/*          Your message has been received, We will contact you soon*/}
            {/*        </span>*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*    <div className="empty_notice">*/}
            {/*      <span>! Please Fill Required Fields !</span>*/}
            {/*    </div>*/}
            {/*  </form>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;

import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";

const Cookies = () => {
  const f1 = useRef();
  const f2 = useRef();
  const f3 = useRef();
  const f4 = useRef();
  const f5 = useRef();
  const f6 = useRef();
  const f7 = useRef();
  const f8 = useRef();
  const f9 = useRef();

  const elmn = (elnum) => {
    let body = "body";
    let element;
    if (elnum === 1) {
      element = f1.current;
    } else if (elnum === 2) {
      element = f2.current;
    } else if (elnum === 3) {
      element = f3.current;
    } else if (elnum === 4) {
      element = f4.current;
    } else if (elnum === 5) {
      element = f5.current;
    } else if (elnum === 6) {
      element = f6.current;
    } else if (elnum === 7) {
      element = f7.current;
    } else if (elnum === 8) {
      element = f8.current;
    } else if (elnum === 9) {
      element = f9.current;
    }
    let words = element.innerHTML.split(" ");
    let speed = 0.03; // 0.05

    let color = "#fff";
    let toColor = "var(--mc2)";

    let escapedColor = color.replace(/[^a-zA-Z0-9]/g, "_");

    let word = "";
    let rate = 0;
    if (!element.classList.contains("stop")) {
      element.classList.add("stop");
      element.innerHTML = "";
      var html = "";
      for (let i = 0; i < words.length; i++) {
        word = "";
        let newword = words[i].split("");
        for (let j = 0; j < newword.length; j++) {
          word +=
            '<span class="letter" style="animation: FrenifyLetterAnimation_tc' +
            " .5s " +
            rate * speed +
            's  forwards">' +
            newword[j] +
            "</span>";
          rate++;
        }
        html += '<span class="word">' + word + "</span>&nbsp";
      }

      const template = document.createElement("style");
      template.innerHTML =
        "<style>@keyframes FrenifyLetterAnimation_tc" +
        " { 60% {transform: translate(20px, 0) scale(1); color: " +
        color +
        ";} 80% {transform: translate(20px, 0) scale(1); color: " +
        color +
        ";} 99% {transform: translate(0) scale(1.2);color: " +
        toColor +
        ";} 100% {transform: translate(0) scale(1); opacity: 1; color: " +
        color +
        ';} }.fn_animated_text[data-color="' +
        color +
        '"],.desc[data-color="' +
        color +
        '"],.fn_animated_text[data-color="' +
        color +
        '"] .letter{color: ' +
        color +
        ";}</style>";

      document.body.appendChild(template);
      setTimeout(function () {
        element.classList.add("done");
      }, 500 + rate * speed * 1000);
      element.innerHTML = html;
    }
  };
  return (
    <div className="metaverse_fn_page">
      <div className="metaverse_fn_pagetitle">
        <div className="container">
          <div className="pagetitle">
            <h3 className="maintitle" data-color="white">
              Cookies
            </h3>
            <p>
              <Link to="/">Home</Link>
              <span className="divider colorp">/</span>
              <span className="text colorp">Cookies</span>
            </p>
          </div>
        </div>
        <div className="fn_cs_title ready">
          <div className="divider" data-color="2">
            <div className="line"></div>
            <div className="middle"></div>
            <div className="left1"></div>
            <div className="left2"></div>
            <div className="right1"></div>
            <div className="right2"></div>
          </div>
        </div>
      </div>

      <div className="metaverse_fn_pagecontent">
        <div className="metaverse_fn_simple">
          <div
            className="container"
            style={{ padding: "50px", marginTop: "-55px" }}
          >
            <Waypoint onEnter={() => elmn(1)}>
              <h3
                className="fn__title fn_animated_text"
                data-color="#fff"
                ref={f1}
              >
                What is a Cookies Policy?
              </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
              <strong>Suggested text:</strong> Our website address is:
              https://frenify.net/envato/frenify/html/metaverse/1/
            </p>
            <Waypoint onEnter={() => elmn(2)}>
              <h3
                className="fn__title fn_animated_text"
                data-color="#fff"
                ref={f2}
              >
                Do You Legally Need a Cookies Policy?
              </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
              <strong>Suggested text:</strong> When visitors leave comments on
              the site we collect the data shown in the comments form, and also
              the visitor’s IP address and browser user agent string to help
              spam detection.
            </p>
            <p style={{ color: "white" }}>
              An anonymized string created from your email address (also called
              a hash) may be provided to the Gravatar service to see if you are
              using it. The Gravatar service privacy policy is available here:
              https://automattic.com/privacy/. After approval of your comment,
              your profile picture is visible to the public in the context of
              your comment.
            </p>
            <Waypoint onEnter={() => elmn(3)}>
              <h3
                className="fn__title fn_animated_text"
                data-color="#fff"
                ref={f3}
              >
                United States
              </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
              <strong>Suggested text:</strong> If you upload images to the
              website, you should avoid uploading images with embedded location
              data (EXIF GPS) included. Visitors to the website can download and
              extract any location data from images on the website.
            </p>
            <Waypoint onEnter={() => elmn(4)}>
              <h3
                className="fn__title fn_animated_text"
                data-color="#fff"
                ref={f4}
              >
                European Union
              </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
              Suggested text: If you leave a comment on our site you may opt-in
              to saving your name, email address and website in cookies. These
              are for your convenience so that you do not have to fill in your
              details again when you leave another comment. These cookies will
              last for one year.
            </p>
            <p style={{ color: "white" }}>
              If you visit our login page, we will set a temporary cookie to
              determine if your browser accepts cookies. This cookie contains no
              personal data and is discarded when you close your browser.
            </p>
            <p style={{ color: "white" }}>
              When you log in, we will also set up several cookies to save your
              login information and your screen display choices. Login cookies
              last for two days, and screen options cookies last for a year. If
              you select "Remember Me", your login will persist for two weeks.
              If you log out of your account, the login cookies will be removed.
            </p>
            <p style={{ color: "white" }}>
              If you edit or publish an article, an additional cookie will be
              saved in your browser. This cookie includes no personal data and
              simply indicates the post ID of the article you just edited. It
              expires after 1 day.
            </p>
            <Waypoint onEnter={() => elmn(5)}>
              <h3
                className="fn__title fn_animated_text"
                data-color="#fff"
                ref={f5}
              >
                What Should be Included in Your Cookies Policy?
              </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
              <strong>Suggested text:</strong> Articles on this site may include
              embedded content (e.g. videos, images, articles, etc.). Embedded
              content from other websites behaves in the exact same way as if
              the visitor has visited the other website.
            </p>
            <p style={{ color: "white" }}>
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with
              that embedded content, including tracking your interaction with
              the embedded content if you have an account and are logged in to
              that website.
            </p>
            <Waypoint onEnter={() => elmn(6)}>
              <h3
                className="fn__title fn_animated_text"
                data-color="#fff"
                ref={f6}
              >
                Definition of Cookies
              </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
              <strong>Suggested text:</strong> If you request a password reset,
              your IP address will be included in the reset email.
            </p>
            <Waypoint onEnter={() => elmn(7)}>
              <h3
                className="fn__title fn_animated_text"
                data-color="#fff"
                ref={f7}
              >
                What You Use the Cookies For
              </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
              <strong>Suggested text:</strong> If you leave a comment, the
              comment and its metadata are retained indefinitely. This is so we
              can recognize and approve any follow-up comments automatically
              instead of holding them in a moderation queue.
            </p>
            <p style={{ color: "white" }}>
              For users that register on our website (if any), we also store the
              personal information they provide in their user profile. All users
              can see, edit, or delete their personal information at any time
              (except they cannot change their username). Website administrators
              can also see and edit that information.
            </p>
            <Waypoint onEnter={() => elmn(8)}>
              <h3
                className="fn__title fn_animated_text"
                data-color="#fff"
                ref={f8}
              >
                How Users can Opt Out or Adjust Settings
              </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
              <strong>Suggested text:</strong> If you have an account on this
              site, or have left comments, you can request to receive an
              exported file of the personal data we hold about you, including
              any data you have provided to us. You can also request that we
              erase any personal data we hold about you. This does not include
              any data we are obliged to keep for administrative, legal, or
              security purposes.
            </p>
            <Waypoint onEnter={() => elmn(9)}>
              <h3
                className="fn__title fn_animated_text"
                data-color="#fff"
                ref={f9}
              >
                Cookies Notice When Asking for Consent
              </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
              <strong>Suggested text:</strong> Visitor comments may be checked
              through an automated spam detection service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;

import React, {useEffect, useState} from "react";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3 from 'web3';
import { Link } from "react-router-dom";
import Logos from "../../assets/new/logo-new.png";

const WalletPopup = () => {
  // const checkAuthentication = async () => {
  //   if (window.ethereum) {
  //     try {
  //       const web3 = new Web3(window.ethereum);
  //       const accounts = await web3.eth.getAccounts();
  //
  //       if (accounts.length > 0) {
  //         setAccount(accounts[0]);
  //         setAuthenticated(true);
  //       }
  //     } catch (error) {
  //       console.error('Error checking Metamask authentication:', error);
  //     }
  //   } else {
  //     console.error('Metamask not found');
  //   }
  // };

  const connectMetamask = async () => {
    console.log('metamask')
    // if (window.ethereum) {
    //   try {
    //     await window.ethereum.request({ method: 'eth_requestAccounts' });
    //     const web3 = new Web3(window.ethereum);
    //     const accounts = await web3.eth.getAccounts();
    //     // let token = jwt.sign({ account: accounts[0] }, '1AF69C48EE17B6CD59D4E32962328');
    //     // console.log(token)
    //     localStorage.setItem('authenticated', '1');
    //     caches.keys().then((names) => {
    //       names.forEach((name) => {
    //         caches.delete(name);
    //       });
    //     });
    //     window.location.reload();
    //   } catch (error) {
    //     console.error('Error connecting to Metamask:', error);
    //     alert('Error connecting to Metamask:', error)
    //   }
    // } else {
    //   console.error('Metamask not found');
    //   alert('Metamask not found')
    // }
  };

  const connectCoinbase = async () => {
    const APP_NAME = 'KittyMeme'
    const APP_LOGO_URL = 'https://example.com/logo.png'
    const DEFAULT_ETH_JSONRPC_URL = `https://mainnet.infura.io/v3/6815728ea335465a86af0c60c4dad992`
    const DEFAULT_CHAIN_ID = 1

    // Initialize Coinbase Wallet SDK
    const coinbaseWallet = new CoinbaseWalletSDK({
      appName: APP_NAME,
      appLogoUrl: APP_LOGO_URL,
      darkMode: false

    })

    // Initialize a Web3 Provider object
    const coinbaseEthereum = coinbaseWallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID)
    console.log('coinbase');
    // coinbaseEthereum.request({ method: 'eth_requestAccounts' }).then(response => console.log(response));
    if(coinbaseEthereum) {
      try {
        await coinbaseEthereum.request({ method: 'eth_requestAccounts' });
        const webC3 = new Web3(coinbaseEthereum);
        const accounts = await webC3.eth.getAccounts();
        localStorage.setItem('authenticated', '1');
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.location.reload();
      } catch (error) {
        console.error('Error connecting to Metamask:', error);
        alert('Error connecting to Metamask:', error);
      }
    }else {
      console.error('Coinbase not found');
      alert('Coinbase not found');
    }
  }
  return (
    <>
      <div className="metaverse_fn_wallet_closer ready"></div>
      <div className="metaverse_fn_walletbox ready">
        <Link to="#" className="fn__closer">
          <img src="assets/svg/cancel.svg" alt="" className="fn__svg replaced-svg" />
        </Link>
        <div className="walletbox">
          <div className="logo-center">
            <Link to="/">
              <img src={Logos} alt="" />
            </Link>
          </div>
          <div className="title_holder">
            <h3>Connect Wallet</h3>
            {/* <p>
              Connect with one of our available wallet providers or create a new
              one.
            </p> */}
          </div>

          <div className="list_holder">
            <ul className="metaverse_fn_items">
              <li>
                <div className="item">
                  <a href="#" className="metaverse_fn_iconbtn" onClick={connectMetamask}>
                        <img src="assets/img/wallet/metamask.png" alt="" /> Metamask
                  </a>
                </div>
              </li>
              {/*<li>*/}
              {/*  <div className="item">*/}
              {/*    <Link to="#" className="metaverse_fn_iconbtn">*/}
              {/*          <img src="assets/img/wallet/walletconnect.png" alt="" /> WalletConnect*/}
              {/*    </Link>*/}
              {/*  </div>*/}
              {/*</li>*/}
              <li>
                <div className="item">
                  <a href="#" className="metaverse_fn_iconbtn" onClick={connectCoinbase}>
                        <img src="assets/img/wallet/coinbase.png" alt="" /> Coinbase
                  </a>
                </div>
              </li>
              {/*<li>*/}
              {/*  <div className="item">*/}
              {/*    <a href="#" className="metaverse_fn_iconbtn">*/}
              {/*          <img src="assets/img/wallet/venly.png" alt="" /> Venly*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*</li>*/}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletPopup;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ReactLogo } from "../../assets/svg/down.svg";

const Collation = () => {
  const [close, setClose] = useState(false);
  return (
    <>
      <div className="metaverse_fn_page">
        <div className="metaverse_fn_pagetitle">
          <div className="container">
            <div className="pagetitle">
              <h3 className="maintitle" data-color="white">
                Collection
              </h3>
              <p>
                <Link to="/">Home</Link>
                <span className="divider colorp">/</span>
                <span className="text colorp" >Collection</span>
              </p>
            </div>
          </div>
          <div className="fn_cs_title ready">
            <div className="divider" data-color="2">
              <div className="line"></div>
              <div className="middle"></div>
              <div className="left1"></div>
              <div className="left2"></div>
              <div className="right1"></div>
              <div className="right2"></div>
            </div>
          </div>
        </div>
    <div style={{paddingLeft:"20px",paddingRight:"20px"}}>
        <div className="metaverse_fn_pagecontent">
          <div className="metaverse_fn_collectionpage">
            <div className="container wide">
              <div className="metaverse_fn_collection">
                <div className="metaverse_fn_filters">
                  <div className={`filter_item filter__type ${close ?"opened":""}`}>
                    <div className="filter_item__header">
                      <Link className="full_link" to="#"></Link>
                      <span
                        className="text"
                        onClick={() => setClose(close ? false : true)}
                      >
                        Type
                      </span>
                      <span className="icon">
                        <ReactLogo
                          className="fn__svg replaced-svg "
                        />
                      </span>
                    </div>
                    <div className="filter_item__content opened">
                      <div className="ic_in">
                        <ul className="items">
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Type"
                                data-id="1"
                              >
                                <span className="text">Human</span>
                                <span className="amount"> - 3100</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Type"
                                data-id="2"
                              >
                                <span className="text">Spirit</span>
                                <span className="amount"> - 4764</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Type"
                                data-id="3"
                              >
                                <span className="text">Fire</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Type"
                                data-id="4"
                              >
                                <span className="text">Sand</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filter_item filter__special opened">
                    <div className="filter_item__header">
                      <Link className="full_link" to="#"></Link>
                      <span className="text">Special</span>
                      <span className="icon">
                        <ReactLogo
                          className="fn__svg replaced-svg "
                        />
                      </span>
                    </div>
                    <div className="filter_item__content">
                      <div className="ic_in">
                        <ul className="items">
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Special"
                                data-id="5"
                              >
                                <span className="text">Fireflies</span>
                                <span className="amount"> - 3100</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Special"
                                data-id="6"
                              >
                                <span className="text">Butterfly</span>
                                <span className="amount"> - 4764</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Special"
                                data-id="7"
                              >
                                <span className="text">Fox Fire</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Special"
                                data-id="8"
                              >
                                <span className="text">Smoke</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Special"
                                data-id="9"
                              >
                                <span className="text">Sakura</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Special"
                                data-id="10"
                              >
                                <span className="text">Fire</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Special"
                                data-id="11"
                              >
                                <span className="text">Earth</span>
                                <span className="amount"> - 1534</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Special"
                                data-id="12"
                              >
                                <span className="text">Water</span>
                                <span className="amount"> - 1235</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="filter_item filter__clothing">
                    <div className="filter_item__header">
                      <Link className="full_link" to="#"></Link>
                      <span className="text">Clothing</span>
                      <span className="icon">
                        <ReactLogo
                          className="fn__svg replaced-svg "
                        />
                      </span>
                    </div>
                    <div className="filter_item__content">
                      <div className="ic_in">
                        <ul className="items">
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Clothing"
                                data-id="13"
                              >
                                <span className="text">Light Kimono</span>
                                <span className="amount">- 3100</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Clothing"
                                data-id="14"
                              >
                                <span className="text">Maroon Yukata</span>
                                <span className="amount"> - 4764</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Clothing"
                                data-id="15"
                              >
                                <span className="text">Blue Kimono</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Clothing"
                                data-id="16"
                              >
                                <span className="text">Green Yukata</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Clothing"
                                data-id="17"
                              >
                                <span className="text">Turquoise Kimono</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Clothing"
                                data-id="18"
                              >
                                <span className="text">Black Yukata</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Clothing"
                                data-id="19"
                              >
                                <span className="text">Azuki Tech Jacket</span>
                                <span className="amount"> - 1534</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Clothing"
                                data-id="20"
                              >
                                <span className="text">Blue Floral Kimono</span>
                                <span className="amount"> - 1235</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="filter_item filter__hair">
                    <div className="filter_item__header">
                      <Link className="full_link" to="#"></Link>
                      <span className="text">Hair</span>
                      <span className="icon">
                        <ReactLogo
                          className="fn__svg replaced-svg "
                        />
                      </span>
                    </div>
                    <div className="filter_item__content">
                      <div className="ic_in">
                        <ul className="items">
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="21"
                              >
                                <span className="text">Maroon Bun</span>
                                <span className="amount"> - 3100</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="22"
                              >
                                <span className="text">Maroon Ponytail</span>
                                <span className="amount"> - 4764</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="23"
                              >
                                <span className="text">Brown Spiky</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="24"
                              >
                                <span className="text">Brown Long</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="25"
                              >
                                <span className="text">Maroon Disheveled</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="26"
                              >
                                <span className="text">Maroon Bob</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="27"
                              >
                                <span className="text">Magenta Long</span>
                                <span className="amount"> - 1534</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="28"
                              >
                                <span className="text">Indigo Long</span>
                                <span className="amount"> - 1235</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="29"
                              >
                                <span className="text">Maroon Short Spiky</span>
                                <span className="amount"> - 345</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Hair"
                                data-id="30"
                              >
                                <span className="text">Blonde Short Spiky</span>
                                <span className="amount"> - 600</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filter_item filter__face">
                    <div className="filter_item__header">
                      <Link className="full_link" to="#"></Link>
                      <span className="text">Face</span>
                      <span className="icon">
                        <ReactLogo
                          className="fn__svg replaced-svg "
                        />
                      </span>
                    </div>

                    <div className="filter_item__content">
                      <div className="ic_in">
                        <ul className="items">
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Face"
                                data-id="31"
                              >
                                <span className="text">
                                  Red Stripes Face Paint
                                </span>
                                <span className="amount"> - 3100</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Face"
                                data-id="32"
                              >
                                <span className="text">Eye Scar</span>
                                <span className="amount"> - 4764</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Face"
                                data-id="33"
                              >
                                <span className="text">
                                  Round Blue Sunglasses
                                </span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Face"
                                data-id="34"
                              >
                                <span className="text">
                                  Red Fang Face Paint
                                </span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Face"
                                data-id="35"
                              >
                                <span className="text">Maroon Disheveled</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filter_item filter__eyes">
                    <div className="filter_item__header">
                      <Link className="full_link" to="#"></Link>
                      <span className="text">Eyes</span>
                      <span className="icon">
                        <ReactLogo
                          className="fn__svg replaced-svg "
                        />
                      </span>
                    </div>

                    <div className="filter_item__content">
                      <div className="ic_in">
                        <ul className="items">
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Eyes"
                                data-id="36"
                              >
                                <span className="text">Closed</span>
                                <span className="amount"> - 3100</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Eyes"
                                data-id="37"
                              >
                                <span className="text">Determined</span>
                                <span className="amount"> - 4764</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Eyes"
                                data-id="38"
                              >
                                <span className="text">Striking</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Eyes"
                                data-id="39"
                              >
                                <span className="text">Chill</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Eyes"
                                data-id="40"
                              >
                                <span className="text">Daydreaming</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="filter_item filter__mouth">
                    <div className="filter_item__header">
                      <Link className="full_link" to="#"></Link>
                      <span className="text">Mouth</span>
                      <span className="icon">
                        <ReactLogo
                          className="fn__svg replaced-svg "
                        />
                      </span>
                    </div>

                    <div className="filter_item__content">
                      <div className="ic_in">
                        <ul className="items">
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Mouth"
                                data-id="41"
                              >
                                <span className="text">Relaxed</span>
                                <span className="amount"> - 3100</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Mouth"
                                data-id="42"
                              >
                                <span className="text">Closed</span>
                                <span className="amount"> - 4764</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Mouth"
                                data-id="43"
                              >
                                <span className="text">Smirk</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Mouth"
                                data-id="44"
                              >
                                <span className="text">Not Bad</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Mouth"
                                data-id="45"
                              >
                                <span className="text">Smoking</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Mouth"
                                data-id="46"
                              >
                                <span className="text">Lipstick</span>
                                <span className="amount"> - 490</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="filter_item filter__neck">
                    <div className="filter_item__header">
                      <Link className="full_link" to="#"></Link>
                      <span className="text">Neck</span>
                      <span className="icon">
                        <ReactLogo
                          className="fn__svg replaced-svg "
                        />
                      </span>
                    </div>

                    <div className="filter_item__content">
                      <div className="ic_in">
                        <ul className="items">
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Neck"
                                data-id="47"
                              >
                                <span className="text">Chill Headphones</span>
                                <span className="amount"> - 3100</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Neck"
                                data-id="48"
                              >
                                <span className="text">Koi Tattoo</span>
                                <span className="amount"> - 4764</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Neck"
                                data-id="49"
                              >
                                <span className="text">Towel</span>
                                <span className="amount"> - 1280</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <label
                                className="checkbox"
                                data-category="Neck"
                                data-id="50"
                              >
                                <span className="text">Zen Headphones</span>
                                <span className="amount"> - 2716</span>
                                <input type="checkbox" />
                                <span className="checkmark">
                                  <img
                                    src="assets/svg/checked.svg"
                                    alt=""
                                    className="fn__svg replaced-svg "
                                  />
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="metaverse_fn_clist">
                  <div
                    className="metaverse_fn_result_box"
                    data-clear-text="Clear All"
                    data-and-text="And"
                    data-or-text="Or"
                  >
                    <div className="filter_count">
                      Filters <span>0</span>
                    </div>
                  </div>

                  <div className="metaverse_fn_result_list">
                    <div className="metaverse_fn_drops">
                      <ul className="grid">
                        <li className="id1 id5">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/1.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Ernet Sharpey</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id1 id2 id3 id5 id6">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/2.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Riley the Ravage</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id7 id8 id3">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/3.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Hissing Ornate</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id4 id7 id9">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/4.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Sawyer</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id2 id10">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/5.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Bell Tameron</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id11 id12">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/6.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Cross Laux</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id1 id13 id20">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/7.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Pathfinder Artery</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id1 id14">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/8.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Sycamoris</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id2 id13">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/9.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Calm Fortunate</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id4 id14">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/10.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Nyx Aged</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id5 id13">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/11.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Path Greaves</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id6 id7 id2">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/12.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Lorenzo Curious</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id7 id8 id3">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/13.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Kenji from Diseth</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id4 id7 id9">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/14.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Moaning Beyett</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id2 id10">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/15.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">
                                    Sieyler from Horwy
                                  </Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id11 id12">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/16.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">
                                    Savannah from Nine
                                  </Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id1 id13 id20">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/17.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">Pack Tanaka</Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="id1 id14">
                          <div className="nft__item">
                            <div className="left__border"></div>
                            <div className="right__border"></div>
                            <div className="item_in">
                              <img src="assets/img/drops/18.jpg" alt="" />
                              <Link
                                to="/collation"
                                className="full_link"
                              ></Link>
                              <div className="title_holder">
                                <h3 className="fn_title">
                                  <Link to="/collation">
                                    Callum from Thumper
                                  </Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Collation;

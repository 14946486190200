import React from "react";

const RightNavigation = () => {
  // const navigate = useNavigate()
  // const[show,setShow]=useState(false)

  return (
    <>
      {/* <div className="metaverse_fn_rightnav_closer ready"></div>
      <div className="metaverse_fn_rightnav ready">
        <Link to="#" className="fn__closer">
          <img src="assets/svg/cancel.svg" alt="" className="fn__svg" />
        </Link>
        <div className="navbox">
          <div className="list_holder">
            <div className="list">
              <Link
                to="https://opensea.io/"
                className="metaverse_fn_button has_icon"
                target="_blank"
              >
                <span className="text">
                  <img src="assets/img/market/opensea.png" alt="" />
                  <span>Opensea</span>
                </span>
              </Link>
              <a
                href="https://discord.com/"
                className="metaverse_fn_button has_icon"
                target="_blank"
              >
                <span className="text">
                  <img src="assets/img/market/discord.png" alt="" />
                  <span>Discord</span>
                </span>
              </a>
            </div>
          </div>
          <div className="nav_holder">
            <span className="icon">
              <img src="assets/svg/down.svg" alt="" className="fn__svg" />
            </span>

            <ul>
              <li>
                <Link to="/">
                  <span className="creative_link">
                    Home
                    <img src="assets/svg/down.svg" alt="" className="fn__svg" />
                  </span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/">
                      <span className="creative_link">Sliced Slider3</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span className="creative_link">Full Slider</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span className="creative_link">Cards Carousel</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span className="creative_link">3D Carousel</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span className="creative_link">ZigZag Carousel</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/mintpage" className="closeit " onClick={()=>{
                  setShow(false)
                  navigate("/mintpage")
                }}>
                  <span className="creative_link">Mint Page</span>
                </Link>
              </li>
              <li>
                <Link to="/#collation">
                  <span className="creative_link">Collection</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <span className="creative_link">
                    Pages
                    <img src="assets/svg/down.svg" alt="" className="fn__svg" />
                  </span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/comingsoon">
                      <span className="creative_link">Coming Soon</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#contact">
                      <span className="creative_link">Contact</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/error">
                      <span className="creative_link">404 Page</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/proteated">
                      <span className="creative_link">Protected Page</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/noresult">
                      <span className="creative_link">Search No Results</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="next">
                      <span className="creative_link">
                        More
                        <img
                          src="assets/svg/down.svg"
                          alt=""
                          className="fn__svg"
                        />
                      </span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/cookies">
                          <span className="creative_link">Cookies</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/policy">
                          <span className="creative_link">Privacy Policy</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/termscondition">
                          <span className="creative_link">
                            Terms &amp; Conditions
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/blogs">
                  <span className="creative_link">Blog</span>
                </Link>
              </li>
              <li>
                <Link to="/blogssingle">
                  <span className="creative_link">Blog Single</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="info_holder">
            <div className="search_holder">
              <input type="text" placeholder="Search here..." />
              <Link to="#">
                <img src="assets/svg/search.svg" alt="" className="fn__svg" />
              </Link>
            </div>
            <div className="copyright">
              <p>
                Copyright 2022 - Designed &amp; Developed by{" "}
                <a
                  href="https://themeforest.net/user/frenify/portfolio"
                  target="_blank"
                >
                  Frenify
                </a>
              </p>
            </div>
            <div className="social_icons">
              <ul>
                <li>
                  <a href="https://twitter.com">
                    <img
                      src="assets/svg/social/twitter-1.svg"
                      alt=""
                      className="fn__svg"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com">
                    <img
                      src="assets/svg/social/facebook-1.svg"
                      alt=""
                      className="fn__svg"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com">
                    <img
                      src="assets/svg/social/instagram-1.svg"
                      alt=""
                      className="fn__svg"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.pinterest.com">
                    <img
                      src="assets/svg/social/pinterest-1.svg"
                      alt=""
                      className="fn__svg"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.behance.net">
                    <img
                      src="assets/svg/social/behance-1.svg"
                      alt=""
                      className="fn__svg"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {show &&
      <div>dfd</div>} */}
    </>
  );
};

export default RightNavigation;

import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="metaverse_fn_page">
      <div className="metaverse_fn_pagetitle">
        <div className="metaverse_fn_pagecontent">
          <div className="bloglist">
            <div className="container">
              <div className="pagetitle">
                <h3 className="maintitle" data-color="white">
                  Blog Page
                </h3>
                <p>
                  <Link to="/">Home</Link>
                  <span className="divider.html" style={{ color: "white" }}>
                    /
                  </span>
                  <span className="text" style={{ color: "white" }}>
                    Blog Page
                  </span>
                </p>
              </div>
            </div>
              <div className="fn_cs_title ready">
                <div className="divider" data-color="2">
                  <div className="line"></div>
                  <div className="middle"></div>
                  <div className="left1"></div>
                  <div className="left2"></div>
                  <div className="right1"></div>
                  <div className="right2"></div>
                </div>
              </div>
            </div>
            <div style={{ paddingLeft: "140px", paddingRight: "140px" }}>
            <div className="metaverse_fn_pagecontent">
              <div className="metaverse_fn_bloglist">
                <div className="container">
                  <div className="bloglist ">
                    <Container>
                      <ul
                        className="fn-isotope ready"
                        style={{ position: "relative", height: "1651.53px" }}
                      >
                        <Row>
                          <li className="isotope-item">
                            <div className="blog__item">
                              <div className="left__border"></div>
                              <div className="right__border"></div>
                              <div className="item_in">
                                <div className="image">
                                  <Link to="/blogs">
                                    <img src="assets/img/blog/1.jpg" alt="" />
                                  </Link>
                                </div>
                                <div className="item_desc">
                                  <div className="meta">
                                    <p>
                                      August 09, 2022 / MetaVerse / 4 Comments
                                    </p>
                                  </div>
                                  <div className="title">
                                    <h3>
                                      <Link to="/blogs">
                                        Reasons Why People Use Discord
                                      </Link>
                                    </h3>
                                  </div>
                                  <div className="desc">
                                    <p className="colorp">
                                      There are many variations of passages of
                                      lorem ipsum available, but the majority
                                      have suffered alteration in some form, by
                                      injected humour
                                    </p>
                                  </div>
                                  <div className="more">
                                    <Link
                                      to="/blogs"
                                      className="metaverse_fn_button"
                                    >
                                      <span className="text">Read More</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li className="isotope-item">
                            <div className="blog__item">
                              <div className="left__border"></div>
                              <div className="right__border"></div>
                              <div className="item_in">
                                <div className="image">
                                  <Link to="/blogs">
                                    <img src="assets/img/blog/2.jpg" alt="" />
                                  </Link>
                                </div>
                                <div className="item_desc">
                                  <div className="meta">
                                    <p>
                                      August 07, 2022 / MetaVerse / 3 Comments
                                    </p>
                                  </div>
                                  <div className="title">
                                    <h3>
                                      <Link to="/blogs">
                                        Rewards for the Zoidster NFTs
                                      </Link>
                                    </h3>
                                  </div>
                                  <div className="desc">
                                    <p className="colorp">
                                      There are many variations of passages of
                                      lorem ipsum available, but the majority
                                      have suffered alteration in some form, by
                                      injected humour
                                    </p>
                                  </div>
                                  <div className="more">
                                    <Link
                                      to="/blogs"
                                      className="metaverse_fn_button"
                                    >
                                      <span className="text">Read More</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </Row>
                        <Row>
                          <li className="isotope-item">
                            <div className="blog__item">
                              <div className="left__border"></div>
                              <div className="right__border"></div>
                              <div className="item_in">
                                <div className="image">
                                  <Link to="/blogs">
                                    <img src="assets/img/blog/3.jpg" alt="" />
                                  </Link>
                                </div>
                                <div className="item_desc">
                                  <div className="meta">
                                    <p>
                                      August 03, 2022 / MetaVerse / 5 Comments
                                    </p>
                                  </div>
                                  <div className="title">
                                    <h3>
                                      <Link to="/blogs">
                                        How the BRAWL-O-SEUM NFT Was Created
                                      </Link>
                                    </h3>
                                  </div>
                                  <div className="desc">
                                    <p className="colorp">
                                      There are many variations of passages of
                                      lorem ipsum available, but the majority
                                      have suffered alteration in some form, by
                                      injected humour
                                    </p>
                                  </div>
                                  <div className="more">
                                    <Link
                                      to="/blogs"
                                      className="metaverse_fn_button"
                                    >
                                      <span className="text">Read More</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li className="isotope-item">
                            <div className="blog__item">
                              <div className="left__border"></div>
                              <div className="right__border"></div>
                              <div className="item_in">
                                <div className="image">
                                  <Link to="/blogs">
                                    <img src="assets/img/blog/4.jpg" alt="" />
                                  </Link>
                                </div>
                                <div className="item_desc">
                                  <div className="meta">
                                    <p>
                                      August 01, 2022 / MetaVerse / 4 Comments
                                    </p>
                                  </div>
                                  <div className="title">
                                    <h3>
                                      <Link to="/blogs">
                                        Land Redemption Day is coming!
                                      </Link>
                                    </h3>
                                  </div>
                                  <div className="desc">
                                    <p className="colorp">
                                      There are many variations of passages of
                                      lorem ipsum available, but the majority
                                      have suffered alteration in some form, by
                                      injected humour
                                    </p>
                                  </div>
                                  <div className="more">
                                    <Link
                                      to="/blogs"
                                      className="metaverse_fn_button"
                                    >
                                      <span className="text">Read More</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </Row>
                      </ul>
                    </Container>
                  </div>

                  <div className="metaverse_fn_pagination">
                    <ul>
                      <li>
                        <span className="current">1</span>
                      </li>
                      <li>
                        <Link to="#">2</Link>
                      </li>
                      <li>
                        <Link to="#">3</Link>
                      </li>
                      <li>
                        <span>...</span>
                      </li>
                      <li>
                        <Link to="#">77</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
  
  );
};

export default Blogs;

import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import slide1 from "../../assets/img/full_slider/1.jpg"
import slide2 from "../../assets/img/full_slider/2.jpg"
import slide3 from "../../assets/img/full_slider/3.jpg"
import slide4 from "../../assets/img/full_slider/4.jpg"
import slide5 from "../../assets/img/full_slider/5.jpg"

const Slider = ({ hide }) => {
  return (
    <Carousel className={!hide && "hide"} interval={500}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide1}
          alt="First slide"
        />
        <Carousel.Caption>
          <div className="swiper-slide">
            <div className="bg_overlay"></div>
            <div className="abs_img"></div>
            <div className="slider_content animet">
              <div className="container">
                <div className="fn_cs_welcome">
                  <h3 className="maintitle">CyberBrokers Collection</h3>
                  <div className="buttons">
                    <Link to="#" className="metaverse_fn_button ccolor">
                      <span className="text">Mint Now</span>
                    </Link>
                    <Link to="#" className="metaverse_fn_button white ccolor">
                      <span className="text">Whitelist Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide2}
          alt="Second slide"
        />

        <Carousel.Caption>
          <div className="swiper-slide">
            <div className="bg_overlay"></div>
            <div className="abs_img"></div>
            <div className="slider_content animet">
              <div className="container">
                <div className="fn_cs_welcome">
                  <h3 className="maintitle">CyberBrokers Collection</h3>
                  <div className="buttons">
                    <Link to="#" className="metaverse_fn_button ccolor">
                      <span className="text">Mint Now</span>
                    </Link>
                    <Link to="#" className="metaverse_fn_button white ccolor">
                      <span className="text">Whitelist Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide3}
          alt="Third slide"
        />

        <Carousel.Caption>
          <div className="swiper-slide">
            <div className="bg_overlay"></div>
            <div className="abs_img"></div>
            <div className="slider_content animet">
              <div className="container">
                <div className="fn_cs_welcome">
                  <h3 className="maintitle">CyberBrokers Collection</h3>
                  <div className="buttons">
                    <Link to="#" className="metaverse_fn_button ccolor">
                      <span className="text">Mint Now</span>
                    </Link>
                    <Link to="#" className="metaverse_fn_button white ccolor">
                      <span className="text">Whitelist Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide4}
          alt="First slide"
        />
        <Carousel.Caption>
          <div className="swiper-slide">
            <div className="bg_overlay"></div>
            <div className="abs_img"></div>
            <div className="slider_content animet">
              <div className="container">
                <div className="fn_cs_welcome">
                  <h3 className="maintitle">CyberBrokers Collection</h3>
                  <div className="buttons">
                    <Link to="#" className="metaverse_fn_button ccolor">
                      <span className="text">Mint Now</span>
                    </Link>
                    <Link to="#" className="metaverse_fn_button white ccolor">
                      <span className="text">Whitelist Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide5}
          alt="First slide"
        />
        <Carousel.Caption>
          <div className="swiper-slide">
            <div className="bg_overlay"></div>
            <div className="abs_img"></div>
            <div className="slider_content animet">
              <div className="container">
                <div className="fn_cs_welcome">
                  <h3 className="maintitle">CyberBrokers Collection</h3>
                  <div className="buttons">
                    <Link to="#" className="metaverse_fn_button ccolor">
                      <span className="text">Mint Now</span>
                    </Link>
                    <Link to="#" className="metaverse_fn_button white ccolor">
                      <span className="text">Whitelist Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;

import React from "react";
import Home from "../componets/home/Home";
import Footer from "../componets/footer/Footer";
import MintPage from "../componets/mintbox/MintPage";
import Collation from "../componets/collation/Collation";
import ComingSoon from "../componets/page/ComingSoon";
import Contact from "../componets/page/Contact";
import Error from "../componets/page/error";
import Protected from "../componets/page/Protected";
import NoResutl from "../componets/page/NoResutl";
import Cookies from "../componets/page/more/Cookies";
import Policy from "../componets/page/more/Policy";
import TermsCondition from "../componets/page/more/TermsCondition";
import Disclaimer from "../componets/page/more/Disclaimer";
import Blogs from "../componets/blogs/Blogs";
import BlogSingle from "../componets/blog_single/BlogSingle";
import Header from "../componets/header/Header";
import Preloader from "../componets/Preloader/Preloader";
import RightNavigation from "../componets/right_navigation/RightNavigation";
import WalletPopup from "../componets/wallet_popup/WalletPopup";
import { Route, Routes } from "react-router-dom";

const Routing = () => {
  return (
    <>
      <Preloader />
      <RightNavigation />
      <WalletPopup />
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mintpage" element={<MintPage />} />
        <Route path="/collation" element={<Collation />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/error" element={<Error />} />
        <Route path="/proteated" element={<Protected />} />
        <Route path="/noresult" element={<NoResutl />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/termscondition" element={<TermsCondition />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogssingle" element={<BlogSingle />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/turmandconditoin" element={<TermsCondition />} />
        <Route path="/disclaimer" element={<Disclaimer />} />

      </Routes>

      <Footer />
    </>
  );
};

export default Routing;

import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./assets/css/plugins.css";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./routes/Index";

function App() {
  return (
    <Router>
      <Routing />
    </Router>
  );
}

export default App;

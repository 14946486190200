import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import sli1 from "../../assets/img/slider/1.png";
import sli2 from "../../assets/img/slider/2.png";
import sli3 from "../../assets/img/slider/3.png";
import sli4 from "../../assets/img/slider/4.png";
import sli5 from "../../assets/img/slider/5.png";
import sli6 from "../../assets/img/slider/6.png";
import sli7 from "../../assets/img/slider/7.png";
import sli8 from "../../assets/img/slider/8.png";
import { ReactComponent as ReactLogo } from "../../assets/svg/mountain.svg";
import { useRef } from "react";
import Img1x1 from "../../assets/img/1x1.jpg";

const Card_Crousal = ({ hide }) => {
  useEffect(() => {
    floww();
    showw();
  }, []);

  const silders = useRef();
  const sliderul = useRef();

  const floww = () => {
    let element = silders.current,
      ul = sliderul.current,
      children = document.getElementsByName("sl_li");
    let length = children.length;

    // stop function
    if (length < 5) {
      return false;
    }

    // build gallery slider
    floww_change_slide(1, element);
    floww_start_autoplay(ul, element);
  };

  const floww_start_autoplay = (ul, element) => {
    let timeout = 5000;
    let time = null;
    clearInterval(time);
    time = setInterval(function () {
      let children = document.getElementsByName("sl_li");
      let newindex = document.getElementsByClassName("current");

      let count = 0;
      for (let i = 0; i < children.length; i++) {
        if (children[i] == newindex[0]) {
          break;
        } else {
          count++;
        }
      }
      let index = count + 2;

      floww_change_slide(index, element);
    }, timeout);
  };

  const floww_change_slide = (index, element) => {
    let ul = sliderul.current,
      children = document.getElementsByName("sl_li");
    let length = children.length;
    index = (index + length) % length;
    if (index == 0) {
      index = length;
    }
    let el = children[index - 1];
    if (el) {
      if (!el.classList.contains("current")) {
        for (let i = 0; i < length; i++) {
          children[i].classList.remove(
            "current",
            "next1",
            "next2",
            "next3",
            "prev1",
            "prev2",
            "prev3"
          );
        }
        el.classList.add("current");
        let next1_index = (index + 1) % length;
        if (next1_index == 0) {
          next1_index = length;
        }
        let next2_index = (index + 2) % length;
        if (next2_index == 0) {
          next2_index = length;
        }
        let next3_index = (index + 3) % length;
        if (next3_index == 0) {
          next3_index = length;
        }

        var prev1_index = (index - 1 + length) % length;
        if (prev1_index == 0) {
          prev1_index = length;
        }
        let prev2_index = (index - 2 + length) % length;
        if (prev2_index == 0) {
          prev2_index = length;
        }
        let prev3_index = (index - 3 + length) % length;
        if (prev3_index == 0) {
          prev3_index = length;
        }

        children[next1_index - 1].classList.add("next1");
        children[next2_index - 1].classList.add("next2");
        children[prev1_index - 1].classList.add("prev1");
        children[prev2_index - 1].classList.add("prev2");
        if (length > 6) {
          children[next3_index - 1].classList.add("next3");
          children[prev3_index - 1].classList.add("prev3");
        }
        floww_calc_call(element);
      }
    }
  };

  const flow_calc = (element) => {
    let w = element.offsetWidth,
      initial_width = "540",
      ratio = "0.925",
      padding = 20;
    padding *= 2;
    let width =
        (w - padding) / 2 > initial_width ? initial_width : (w - padding) / 2,
      height = width * ratio + padding,
      ul = sliderul.current,
      children = document.getElementsByName("sl_li");
    if (ul) {
      ul.style.height = height + "px";
    }
    let imagArr = document.getElementsByClassName("img_holder");

    for (let i = 0; i > imagArr.length; i++) {
      children[i].style.height = height - padding + "px";
      children[i].style.width = width + "px";
    }
    for (let i = 0; i > children.length; i++) {
      children[i].style.height = height + "px";
      children[i].style.width = width + "px";
    }

    let current_left = w / 2 - width / 2,
      next1_left = current_left + width / 2.5,
      next2_left = next1_left + width / 2.5,
      next3_left = next2_left + width / 2.5,
      prev1_left = current_left - width / 2.5,
      prev2_left = prev1_left - width / 2.5,
      prev3_left = prev1_left - width / 2.5;
    for (let i = 0; i < children.length; i++) {
      children[i].style.left = "50%";
      children[i].style.transform = "scale(0)";
    }

    let current = document.getElementsByClassName("current");
    current[0].style.left = current_left + "px";
    current[0].style.top = 0;
    current[0].style.transform = "scale(1) translateZ(0) rotate(0)";

    let next1 = document.getElementsByClassName("next1");
    next1[0].style.left = next1_left + "px";
    next1[0].style.top = "90px";
    next1[0].style.transform = "scale(1) translateZ(0) rotate(15deg)";

    let next2 = document.getElementsByClassName("next2");
    next2[0].style.left = next2_left + "px";
    next2[0].style.top = "240px";
    next2[0].style.transform = "scale(1) translateZ(0) rotate(30deg)";

    let prev1 = document.getElementsByClassName("prev1");
    prev1[0].style.left = prev1_left + "px";
    prev1[0].style.top = "90px";
    prev1[0].style.transform = "scale(1) translateZ(0) rotate(-15deg)";

    let prev2 = document.getElementsByClassName("prev2");
    prev2[0].style.left = prev2_left + "px";
    prev2[0].style.top = "240px";
    prev2[0].style.transform = "scale(1) translateZ(0) rotate(-30deg)";

    if (children.length > 6) {
      let prev3 = document.getElementsByClassName("prev3");
      prev3[0].style.left = prev3_left + "px";
      prev3[0].style.top = "360px";
      prev3[0].style.transform = "scale(1) translateZ(0) rotate(-45deg)";

      let next3 = document.getElementsByClassName("next3");
      next3[0].style.left = next3_left + "px";
      next3[0].style.top = "360px";
      next3[0].style.transform = "scale(1) translateZ(0) rotate(45deg)";
    }
  };

  const floww_calc_call = (element) => {
    if (typeof element === "undefined") {
      element = silders.current;
      flow_calc(element);
    } else {
      flow_calc(element);
    }
  };

  const showw = () => {
    let snowflakes_count = 200;

    // let base_css = ``; // Put your custom base css here

    if (typeof total !== "undefined") {
      // snowflakes_count = total;
    }

    // This function allows you to turn on and off the snow
    function toggle_snow() {
      document.getElementById("snow").style.display = "block";
    }

    // Creating snowflakes
    const spawn_snow = (snow_density = 200) => {
      snow_density -= 1;
      for (let x = 0; x < snow_density; x++) {
        let board = document.createElement("div");
        board.className = "snowflake";

        document.getElementById("snow").appendChild(board);
      }
    };

    // Append style for each snowflake to the head
    const add_css = (rule) => {
      let css = document.createElement("style");
      css.type = "text/css";
      css.appendChild(document.createTextNode(rule)); // Support for the rest
      document.getElementsByTagName("head")[0].appendChild(css);
    };

    // Math
    const random_int = (value = 100) => {
      return Math.floor(Math.random() * value) + 1;
    };

    const random_range = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    // Create style for snowflake
    const spawnSnowCSS = (snow_density = 200) => {
      let snowflake_name = "snowflake";
      let rule = ``;

      for (let i = 1; i < snow_density; i++) {
        let random_x = Math.random() * 100; // vw
        let random_offset = random_range(-100000, 100000) * 0.0001; // vw;
        let random_x_end = random_x + random_offset;
        let random_x_end_yoyo = random_x + random_offset / 2;
        let random_yoyo_time = random_range(30000, 80000) / 100000;
        let random_yoyo_y = random_yoyo_time * 100; // vh
        let random_scale = Math.random();
        let fall_duration = random_range(10, 30) * 1; // s
        let fall_delay = random_int(30) * -1; // s
        let opacity_ = Math.random();

        rule += `
        .${snowflake_name}:nth-child(${i}) {
            opacity: ${opacity_};
            transform: translate(${random_x}vw, -10px) scale(${random_scale});
            animation: fall-${i} ${fall_duration}s ${fall_delay}s linear infinite;
        }

        @keyframes fall-${i} {
            ${random_yoyo_time * 100}% {
                transform: translate(${random_x_end}vw, ${random_yoyo_y}vh) scale(${random_scale});
            }

            to {
                transform: translate(${random_x_end_yoyo}vw, 100vh) scale(${random_scale});
            }
            
        }
        `;
      }

      add_css(rule);
    };

    // Load the rules and execute after the DOM loads
    window.onload = function () {
      spawnSnowCSS(snowflakes_count);
      spawn_snow(snowflakes_count);
    };
    if ("#snow".length) {
      spawnSnowCSS(snowflakes_count);
      spawn_snow(snowflakes_count);
    }
  };

  const imgclick = (id) => {
    let element = silders.current;
    floww_change_slide(id, element);
  };

  return (
    <>
      <section id="cards_slider">
        <div className="section_bg">
          <div id="snow"></div>
        </div>

        <div className="section_content">
          <div className="slider_top" style={{ height: "889px" }}>
            <div className="slider_content">
              <div className="container">
                <div className="fn_cs_welcome">
                  <h3 className="maintitle">CyberBrokers Collection</h3>
                  <div className="buttons">
                    <Link href="#" className="metaverse_fn_button">
                      <span className="text">Mint Now</span>
                    </Link>
                    <Link href="#" className="metaverse_fn_button white">
                      <span className="text">Whitelist Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="frenify_cards_gallery"
            style={{ marginTop: "-754px" }}
            data-initial-width="540"
            data-ratio="0.925"
            ref={silders}
          >
            <ul ref={sliderul}>
              <li
                name="sl_li"
                onClick={() => imgclick(1)}
                style={{ height: "540px", width: "540px" }}
              >
                <div
                  className="img_holder "
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="left__border"></div>
                  <div className="right__border"></div>
                  <div
                    className="item_in"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="o_img"
                      style={{ backgroundImage: `url(${sli1})` }}
                    ></div>
                    <img src={Img1x1} alt="" />
                  </div>
                </div>
              </li>
              <li
                name="sl_li"
                onClick={() => imgclick(2)}
                style={{ height: "540px", width: "540px" }}
              >
                <div
                  className="img_holder"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="left__border"></div>
                  <div className="right__border"></div>
                  <div
                    className="item_in"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="o_img"
                      style={{ backgroundImage: `url(${sli2})` }}
                    ></div>
                    <img src={Img1x1} alt="" />
                  </div>
                </div>
              </li>
              <li
                name="sl_li"
                onClick={() => imgclick(3)}
                style={{ height: "540px", width: "540px" }}
              >
                <div
                  className="img_holder "
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="left__border"></div>
                  <div className="right__border"></div>
                  <div
                    className="item_in"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="o_img"
                      style={{ backgroundImage: `url(${sli3})` }}
                    ></div>
                    <img src={Img1x1} alt="" />
                  </div>
                </div>
              </li>
              <li
                name="sl_li"
                onClick={() => imgclick(4)}
                style={{ height: "540px", width: "540px" }}
              >
                <div
                  className="img_holder "
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="left__border"></div>
                  <div className="right__border"></div>
                  <div
                    className="item_in"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="o_img"
                      style={{ backgroundImage: `url(${sli4})` }}
                    ></div>
                    <img src={Img1x1} alt="" />
                  </div>
                </div>
              </li>
              <li
                name="sl_li"
                onClick={() => imgclick(5)}
                style={{ height: "540px", width: "540px" }}
              >
                <div
                  className="img_holder "
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="left__border"></div>
                  <div className="right__border"></div>
                  <div
                    className="item_in"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="o_img"
                      style={{ backgroundImage: `url(${sli5})` }}
                    ></div>
                    <img src={Img1x1} alt="" />
                  </div>
                </div>
              </li>
              <li
                name="sl_li"
                onClick={() => imgclick(6)}
                style={{ height: "540px", width: "540px" }}
              >
                <div
                  className="img_holder "
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="left__border"></div>
                  <div className="right__border"></div>
                  <div
                    className="item_in"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="o_img"
                      style={{ backgroundImage: `url(${sli6})` }}
                    ></div>
                    <img src={Img1x1} alt="" />
                  </div>
                </div>
              </li>
              <li
                name="sl_li"
                onClick={() => imgclick(7)}
                style={{ height: "540px", width: "540px" }}
              >
                <div
                  className="img_holder "
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="left__border"></div>
                  <div className="right__border"></div>
                  <div
                    className="item_in"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="o_img"
                      style={{ backgroundImage: `url(${sli7})` }}
                    ></div>
                    <img src={Img1x1} alt="" />
                  </div>
                </div>
              </li>
              <li
                name="sl_li"
                onClick={() => imgclick(8)}
                style={{ height: "540px", width: "540px" }}
              >
                <div
                  className="img_holder "
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="left__border"></div>
                  <div className="right__border"></div>
                  <div
                    className="item_in"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="o_img"
                      style={{ backgroundImage: `url(${sli8})` }}
                    ></div>
                    <img src={Img1x1} alt="" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="section_divider">
          <ReactLogo alt="" className="fn__svg replaced-svg" />
        </div>
      </section>
    </>
  );
};

export default Card_Crousal;

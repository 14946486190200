import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios'


const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [massage, setMassage] = useState('');
  const [tel, setTel] = useState('');
  const [subject, setSubjet] = useState('');

  
  const handleSumbit = (e) => {
 const url ="http://localhost/metaverse/contact.php";
 let fData = new FormData();
 fData.append('name',name);
 fData.append('email',email);
 fData.append('massage',massage);
 fData.append('tel',tel);
 fData.append('subject',subject);

 axios.post(url,fData)
 .then((response)=> {
    setName('')
    setEmail('')
    setMassage('')
    setTel('')
    setSubjet('')
  })
 .catch(error=> {});
}
  return (
    <div>
      <div className="metaverse_fn_pagecontent">
        <div className="metaverse_fn_contactpage">
          <div className="container">
            <div className="pagetitle">
              <div
                className="metaverse_fn_pagetitle"
                style={{ marginBottom: "5%" }}
              >
                <h3 className="maintitle" data-color="white">
                  Contact
                </h3>
                <p>
                  <a href="index.html">Home</a>
                  <span className="divider colorp">/</span>
                  <span className="text colorp">Contact</span>
                </p>
              </div>
              <div className="fn_cs_title ready">
                <div className="divider" data-color="2">
                  <div className="line"></div>
                  <div className="middle"></div>
                  <div className="left1"></div>
                  <div className="left2"></div>
                  <div className="right1"></div>
                  <div className="right2"></div>
                </div>
              </div>
            </div>
            <div className="fn_cs_contact_info" style={{marginTop: "6%"}}>
              <ul>
                <li>
                  <div className="item">
                    <h4 className="label">Phone</h4>
                    <h3>
                      <Link
                        href="tel:+770221770505"
                        className="fn_animated_text"
                        data-color="#fff"
                      >
                        +77 022 177 05 05
                      </Link>
                    </h3>
                    <h4 className="lable">Email</h4>
                    <h3>
                      <Link
                        href="mailto:frenifyteam@gmail.com"
                        className="fn_animated_text"
                        data-color="#fff"
                      >
                        frenifyteam@gmail.com
                      </Link>
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <h4 className="label">Office</h4>
                    <h3 className="fn_animated_text" data-color="#fff">
                      124 S Main St, Scottville, MI 49454, United States
                    </h3>
                  </div>
                </li>
              </ul>
            </div>

            <div className="fn_cs_contact_form">
              <form
                action="/"
                method="post"
                className="contact_form"
                id="contact_form"
                autocomplete="off"
              >
                <div className="input_list">
                  <ul>
                    <li>
                      <input id="name" type="text" placeholder="Your Name *" 
						 value={name}
                     onChange={(e)=>{setName(e.target.value)}}
					  />
                    </li>
                    <li>
                      <input
                        id="email"
                        type="text"
                        placeholder="Your Email *"
						value={email}
                     onChange={(e)=>{setEmail(e.target.value)}}
                      />
                    </li>
                    <li>
                      <input
                        id="tel"
                        type="text"
                        placeholder="Your Phone (optional)"
						value={tel}
                     onChange={(e)=>{setTel(e.target.value)}}
                      />
                    </li>
                    <li>
                      <input
                        id="subject"
                        type="text"
                        placeholder="Topic (optional)"
						value={subject}
                     onChange={(e)=>{setSubjet(e.target.value)}}
                      />
                    </li>
                    <li className="full">
                      <textarea
                        id="message"
                        placeholder="Your Message *"
						value={massage}
                     onChange={(e)=>{setMassage(e.target.value)}}
                      ></textarea>
                    </li>
                    <li className="full">
                      <label className="fn__checkbox">
                        <input type="checkbox" />
                        <span className="fn__checkmark">
                          <img
                            src="assets/svg/checked.svg"
                            alt=""
                            className="fn__svg"
                          />
                        </span>
                        <p>
                          I’m okay with getting emails and having that activity
                          tracked to improve my experience.
                        </p>
                      </label>
                      <div>
                        <Link
                          id="send_message"
                          href="#"
                          className="metaverse_fn_button full"
						  onClick={()=>handleSumbit()}

                        >
                          <span className="text">Send Message</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className="returnmessage"
                  data-success="Your message has been received, We will contact you soon."
                ></div>
                <div className="empty_notice">
                  <span>! Please Fill Required Fields !</span>
                </div>
              </form>
            </div>

            <div className="fn_cs_desc">
              <p className="desc fn_animated_text" data-color="#fff">
                Duis eu lorem ut mauris pulvinar auctor. Maecenas et dapibus
                orci, eleifend euismod justo. Quisque luctus turpis eu tristique
                feugiat.
              </p>
              <p className="desc fn_animated_text" data-color="#fff">
                Praesent ac magna feugiat, interdum lacus ac, interdum dui.
                Pellentesque id quam quis enim malesuada rutrum. Orci varius
                natoque penatibus et magnis dis parturient montes, nascetur
                ridiculus mus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const f1 = useRef();

  const elmn = (elnum) => {
    let body = "body";
    let element;
    if (elnum === 1) {
      element = f1.current;
    } 
    let words = element.innerHTML.split(" ");
    let speed = 0.03; // 0.05

    let color = "#fff";
    let toColor = "var(--mc2)";

    let escapedColor = color.replace(/[^a-zA-Z0-9]/g, "_");

    let word = "";
    let rate = 0;
    if (!element.classList.contains("stop")) {
      element.classList.add("stop");
      element.innerHTML = "";
      var html = "";
      for (let i = 0; i < words.length; i++) {
        word = "";
        let newword = words[i].split("");
        for (let j = 0; j < newword.length; j++) {
          word +=
            '<span class="letter" style="animation: FrenifyLetterAnimation_tc' +
            " .5s " +
            rate * speed +
            's  forwards">' +
            newword[j] +
            "</span>";
          rate++;
        }
        html += '<span class="word">' + word + "</span>&nbsp";
      }

      const template = document.createElement("style");
      template.innerHTML =
        "<style>@keyframes FrenifyLetterAnimation_tc" +
        " { 60% {transform: translate(20px, 0) scale(1); color: " +
        color +
        ";} 80% {transform: translate(20px, 0) scale(1); color: " +
        color +
        ";} 99% {transform: translate(0) scale(1.2);color: " +
        toColor +
        ";} 100% {transform: translate(0) scale(1); opacity: 1; color: " +
        color +
        ';} }.fn_animated_text[data-color="' +
        color +
        '"],.desc[data-color="' +
        color +
        '"],.fn_animated_text[data-color="' +
        color +
        '"] .letter{color: ' +
        color +
        ";}</style>";

      document.body.appendChild(template);
      setTimeout(function () {
        element.classList.add("done");
      }, 500 + rate * speed * 1000);
      element.innerHTML = html;
    }
  
  };
  return (
    <iv className="metaverse_fn_page">
      <div className="metaverse_fn_pagetitle">
        <div className="container">
          <div className="pagetitle">
            <h3 className="maintitle" data-color="white">
              Disclaimer
            </h3>
            <p>
              <Link to="/">Home</Link>
              <span className="divider colorp">/</span>
              <span className="text colorp">Disclaimer</span>
            </p>
          </div>
        </div>
        <div className="fn_cs_title ready">
          <div className="divider" data-color="2">
            <div className="line"></div>
            <div className="middle"></div>
            <div className="left1"></div>
            <div className="left2"></div>
            <div className="right1"></div>
            <div className="right2"></div>
          </div>
        </div>
      </div>

      <div className="metaverse_fn_pagecontent">
        <div className="metaverse_fn_simple">
          <div
            className="container"
            style={{ padding: "50px", marginTop: "-55px" }}
          >
            <Waypoint onEnter={() => elmn(1)}>
                <h3
                  ref={f1}
                  className="fn__title fn_animated_text "
                  data-color="#fff"
                >
                  LEGAL DISCLAIMER
                </h3>
            </Waypoint>
            <div className="fn__divider">
              <span className="line"></span>
              <span className="a1"></span>
              <span className="a2"></span>
              <span className="a3"></span>
              <span className="a4"></span>
              <span className="a5"></span>
            </div>
            <p style={{ color: "white" }}>
            For Open Community Review and Is Subject To Change
            PLEASE READ THE ENTIRETY OF THIS "LEGAL DISCLAIMER" SECTION CAREFULLY. NOTHING HEREIN CONSTITUTES LEGAL, FINANCIAL, BUSINESS OR TAX ADVICE AND YOU SHOULD CONSULT YOUR OWN LEGAL, FINANCIAL, TAX OR OTHER PROFESSIONAL ADVISOR(S) BEFORE ENGAGING IN ANY ACTIVITY IN CONNECTION HEREWITH. NEITHER KITTY (THE COMPANY), ANY OF THE PROJECT TEAM MEMBERS (THE KITTY TEAM) WHO HAVE WORKED ON KITTY (AS DEFINED HEREIN) OR PROJECT TO DEVELOP KITTY IN ANY WAY WHATSOEVER, ANY DISTRIBUTOR/VENDOR OF $KITTY TOKENS (THE DISTRIBUTOR), NOR ANY SERVICE PROVIDER SHALL BE LIABLE FOR ANY KIND OF DIRECT OR INDIRECT DAMAGE OR LOSS WHATSOEVER WHICH YOU MAY SUFFER IN CONNECTION WITH ACCESSING THIS WHITEPAPER, THE WEBSITE AT HTTPS://WWW.KITTYMEMECOIN.COM/ (THE WEBSITE) OR ANY OTHER WEBSITES OR MATERIALS PUBLISHED BY THE COMPANY.<br/>
            <strong>Project purpose:</strong> You agree that you are acquiring $KITTY to participate in Kitty and to obtain services on the ecosystem thereon. The Company, the Distributor and their respective affiliates would develop and contribute to the underlying source code for Kitty Meme Coin. The Company is acting solely as an arms’ length third party in relation to the $KITTY distribution, and not in the capacity as a financial advisor or fiduciary of any person with regard to the distribution of $KITTY.<br/>
            <strong>Nature of the Whitepaper:</strong> The Whitepaper and the Website are intended for general informational purposes only and do not constitute a prospectus, an offer document, an offer of securities, a solicitation for investment, or any offer to sell any product, item, or asset (whether digital or otherwise). The information herein may not be exhaustive and does not imply any element of a contractual relationship. There is no assurance as to the accuracy or completeness of such information and no representation, warranty or undertaking is or purported to be provided as to the accuracy or completeness of such information. Where the Whitepaper or the Website includes information that has been obtained from third party sources, the Company, the Distributor, their respective affiliates and/or the Kitty team have not independently verified the accuracy or completeness of such information. Further, you acknowledge that circumstances may change and that the Whitepaper or the Website may become outdated as a result; and neither the Company nor the Distributor is under any obligation to update or correct this document in connection therewith.<br/>
            <strong>Token Documentation:</strong> Nothing in the Whitepaper or the Website constitutes any offer by the Company, the Distributor, or the Kitty team to sell any $KITTY (as defined herein) nor shall it or any part of it nor the fact of its presentation form the basis of, or be relied upon in connection with, any contract or investment decision. Nothing contained in the Whitepaper or the Website is or may be relied upon as a promise, representation or undertaking as to the future performance of Kitty Meme Coin. The agreement between the Distributor (or any third party) and you, in relation to any distribution or transfer of $KITTY, is to be governed only by the separate terms and conditions of such agreement.<br/>
            The information set out in the Whitepaper and the Website is for community discussion only and is not legally binding. No person is bound to enter into any contract or binding legal commitment in relation to the acquisition of $KITTY, and no digital asset or other form of payment is to be accepted on the basis of the Whitepaper or the Website. The agreement for distribution of $KITTY and/or continued holding of $KITTY shall be governed by a separate set of Terms and Conditions or Token Distribution Agreement (as the case may be) setting out the terms of such distribution and/or continued holding of $KITTY (the Terms and Conditions), which shall be separately provided to you or made available on the Website. The Terms and Conditions must be read together with the Whitepaper. In the event of any inconsistencies between the Terms and Conditions and the Whitepaper or the Website, the Terms and Conditions shall prevail.<br/>
            Deemed Representations and Warranties: By accessing the Whitepaper or the Website (or any part thereof), you shall be deemed to represent and warrant to the Company, the Distributor, their respective affiliates, and the Kitty team as follows:<br/>
            1. 1.
            in any decision to acquire any $KITTY, you have shall not rely on any statement set out in the Whitepaper or the Website;<br/>
            2. 2.
            you will and shall at your own expense ensure compliance with all laws, regulatory requirements and restrictions applicable to you (as the case may be);<br/>
            3. 3.
            you acknowledge, understand and agree that $KITTY may have no value, there is no guarantee or representation of value or liquidity for $KITTY, and $KITTY is not an investment product nor is it intended for any speculative investment whatsoever;
            4. 4.
            none of the Company, the Distributor, their respective affiliates, and/or the Kitty team members shall be responsible for or liable for the value of $KITTY, the transferability and/or liquidity of $KITTY and/or the availability of any market for $KITTY through third parties or otherwise; and<br/>
            5. 5.
            you acknowledge, understand and agree that you are not eligible to participate in the distribution of $KITTY if you are a citizen, national, resident (tax or otherwise), domiciliary and/or green card holder of a geographic area or country (i) where it is likely that the distribution of $KITTY would be construed as the sale of a security (howsoever named), financial service or investment product and/or (ii) where participation in token distributions is prohibited by applicable law, decree, regulation, treaty, or administrative act (including without limitation the United States of America, Canada, and the People's Republic of China); and to this effect you agree to provide all such identity verification document when requested in order for the relevant checks to be carried out.<br/>
            The Company, the Distributor and the Kitty team do not and do not purport to make, and hereby disclaims, all representations, warranties or undertaking to any entity or person (including without limitation warranties as to the accuracy, completeness, timeliness, or reliability of the contents of the Whitepaper or the Website, or any other materials published by the Company or the Distributor). To the maximum extent permitted by law, the Company, the Distributor, their respective affiliates and service providers shall not be liable for any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including, without limitation, any liability arising from default or negligence on the part of any of them, or any loss of revenue, income or profits, and loss of use or data) arising from the use of the Whitepaper or the Website, or any other materials published, or its contents (including without limitation any errors or omissions) or otherwise arising in connection with the same. Prospective acquirors of $KITTY should carefully consider and evaluate all risks and uncertainties (including financial and legal risks and uncertainties) associated with the distribution of $KITTY, the Company, the Distributor and the Kitty team.<br/>
            $KITTY Token: $KITTY are designed to be utilised, and that is the goal of the $KITTY distribution. In fact, the project to develop Kitty would fail if all $KITTY holders simply held onto their $KITTY and did nothing with it. In particular, it is highlighted that $KITTY:<br/>
            1. 1.
            does not have any tangible or physical manifestation, and does not have any intrinsic value (nor does any person make any representation or give any commitment as to its value);<br/>
            2. 2.
            is non-refundable and cannot be exchanged for cash (or its equivalent value in any other digital asset) or any payment obligation by the Company, the Distributor or any of their respective affiliates;<br/>
            3. 3.
            does not represent or confer on the token holder any right of any form with respect to the Company, the Distributor (or any of their respective affiliates), or its revenues or assets, including without limitation any right to receive future dividends, revenue, shares, ownership right or stake, share or security, any voting, distribution, redemption, liquidation, proprietary (including all forms of intellectual property or licence rights), right to receive accounts, financial statements or other financial data, the right to requisition or participate in shareholder meetings, the right to nominate a director, or other financial or legal rights or equivalent rights, or intellectual property rights or any other form of participation in or relating to Kitty Meme Coin, the Company, the Distributor and/or their service providers;<br/>
            4. 4.
            is not intended to represent any rights under a contract for differences or under any other contract the purpose or pretended purpose of which is to secure a profit or avoid a loss;<br/>
            5. 5.
            is not intended to be a representation of money (including electronic money), security, commodity, bond, debt instrument, unit in a collective investment scheme or any other kind of financial instrument or investment;<br/>
            6. 6.
            is not a loan to the Company, the Distributor or any of their respective affiliates, is not intended to represent a debt owed by the Company, the Distributor or any of their respective affiliates, and there is no expectation of profit; and<br/>
            7. 7.
            does not provide the token holder with any ownership or other interest in the Company, the Distributor or any of their respective affiliates.
            Notwithstanding the $KITTY distribution, users have no economic or legal right over or beneficial interest in the assets of the Company, the Distributor, or any of their affiliates after the token distribution.
            To the extent a secondary market or exchange for trading $KITTY does develop, it would be run and operated wholly independently of the Company, the Distributor, the distribution of $KITTY and Kitty Meme Coin. Neither the Company nor the Distributor will create such secondary markets nor will either entity act as an exchange for $KITTY.<br/>
            <strong>Informational purposes only: </strong>The information set out herein is only conceptual, and describes the future development goals for Kitty to be developed. In particular, the project roadmap in the Whitepaper is being shared in order to outline some of the plans of the Kitty team, and is provided solely for INFORMATIONAL PURPOSES and does not constitute any binding commitment. Please do not rely on this information in deciding whether to participate in the token distribution because ultimately, the development, release, and timing of any products, features or functionality remains at the sole discretion of the Company, the Distributor or their respective affiliates, and is subject to change. Further, the Whitepaper or the Website may be amended or replaced from time to time. There are no obligations to update the Whitepaper or the Website, or to provide recipients with access to any information beyond what is provided herein.<br/>
            <strong>Regulatory approval: </strong>No regulatory authority has examined or approved, whether formally or informally, any of the information set out in the Whitepaper or the Website. No such action or assurance has been or will be taken under the laws, regulatory requirements or rules of any jurisdiction. The publication, distribution or dissemination of the Whitepaper or the Website does not imply that the applicable laws, regulatory requirements or rules have been complied with.<br/>
            <strong>Cautionary Note on forward-looking statements: </strong>All statements contained herein, statements made in press releases or in any place accessible by the public and oral statements that may be made by the Company, the Distributor and/or the Kitty team, may constitute forward-looking statements (including statements regarding the intent, belief or current expectations with respect to market conditions, business strategy and plans, financial condition, specific provisions and risk management practices). You are cautioned not to place undue reliance on these forward-looking statements given that these statements involve known and unknown risks, uncertainties and other factors that may cause the actual future results to be materially different from that described by such forward-looking statements, and no independent third party has reviewed the reasonableness of any such statements or assumptions. These forward-looking statements are applicable only as of the date indicated in the Whitepaper, and the Company, the Distributor as well as the Kitty team expressly disclaim any responsibility (whether express or implied) to release any revisions to these forward-looking statements to reflect events after such date.<br/>
            <strong>References to companies and platforms: </strong>The use of any company and/or platform names or trademarks herein (save for those which relate to the Company, the Distributor or their respective affiliates) does not imply any affiliation with, or endorsement by, any third party. References in the Whitepaper or the Website to specific companies and platforms are for illustrative purposes only.<br/>
            <strong>English language: </strong>The Whitepaper and the Website may be translated into a language other than English for reference purpose only and in the event of conflict or ambiguity between the English language version and translated versions of the Whitepaper or the Website, the English language versions shall prevail. You acknowledge that you have read and understood the English language version of the Whitepaper and the Website.<br/>
            <strong>No Distribution:</strong> No part of the Whitepaper or the Website is to be copied, reproduced, distributed or disseminated in any way without the prior written consent of the Company or the Distributor. By attending any presentation on this Whitepaper or by accepting any hard or soft copy of the Whitepaper, you agree to be bound by the foregoing limitations.<br/>
            </p>


          </div>
        </div>
      </div>
    </iv>
  );
};

export default Disclaimer;

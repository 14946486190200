import React from "react";
import { Link } from "react-router-dom";
import ZigZag from "../zigzag/ZigZag";
// import Logos from "../../assets/new/logo-new.png"
import Logos from "../../assets/new/logo.png";
import { ReactComponent as ReactLogoT } from "../../assets/svg/social/x.svg";
import { ReactComponent as ReactLogo1 } from "../../assets/svg/social/facebook-1.svg";
import { ReactComponent as ReactLogo2 } from "../../assets/svg/social/instagram-1.svg";
import { ReactComponent as ReactLogo3 } from "../../assets/svg/social/pinterest-1.svg";
import { ReactComponent as ReactLogo4 } from "../../assets/svg/social/behance-1.svg";
import { ReactComponent as ReactLogo8 } from "../../assets/svg/social/telegram.svg";
import { ReactComponent as ReactLogo9 } from "../../assets/svg/social/reddit.svg";
import { ReactComponent as ReactLogo10 } from "../../assets/svg/social/discord.svg";

const Footer = () => {
  return (
    <>
      <footer id="footer" style={{ textAlign: "center" }}>
        <div className="container">
          <div className="footer">
            <div className="right_part" style={{ marginTop: "30px" }}>
              <ul className="mob-list">
                <li>
                  <div className="buttons">
                    <a
                      href="https://discord.com/invite/wQzuBtcJEP"
                      target="_blank"
                      className="text-white"
                    >
                      <ReactLogo10 className="fn__svg replaced-svg home__svg" />
                    </a>
                    <a
                      href="https://www.facebook.com/Kittymemecoin?mibextid=ZbWKwL"
                      target="_blank"
                      className="text-white ms-3"
                    >
                      <ReactLogo1 className="fn__svg replaced-svg home__svg" />
                    </a>
                    <a
                      href="https://www.instagram.com/kittymemecoin/"
                      target="_blank"
                      className="text-white ms-3"
                    >
                      <ReactLogo2 className="fn__svg replaced-svg home__svg" />
                    </a>
                    <a
                      href="https://t.me/kitty_memecoin"
                      target="_blank"
                      className="text-white ms-3"
                    >
                      <ReactLogo8 className="fn__svg replaced-svg home__svg" />
                    </a>
                    
                    &nbsp;
                    <a
                      href="https://twitter.com/kittymemecoin?t=hoPHtIaZ2mPQjxNHANicyA&s=08"
                      target="_blank"
                      className="text-white ms-3"
                    >
                      <ReactLogoT className="fn__svg replaced-svg home__svg" />
                    </a>
                    &nbsp;
                    {/*<a href="https://www.reddit.com" target="_blank" className="text-white">*/}
                    {/*  <ReactLogo9 className="fn__svg replaced-svg home__svg" />*/}
                    {/*</a>*/}
                  </div>
                </li>
              </ul>
            </div>
            <div className="left_part" style={{ marginTop: "30px" }}>
              <ul>
                <li>
                  <Link className="creative_link mt-3" to="/policy">
                    Privacy Policy
                  </Link>
                </li>
                {/*<li>*/}
                {/*  <Link className="creative_link" to="/cookies">*/}
                {/*    Cookies*/}
                {/*  </Link>*/}
                {/*</li>*/}
                <li>
                  <Link className="creative_link mt-3" to="/turmandconditoin">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link className="creative_link mt-3" to="/disclaimer">
                    Disclaimer
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <br />
          <br />
          <center style={{ color: "white" }}>
            {/* <img
              src={Logos}
              alt=""
              className="footer-logo"
              style={{ width: "auto", height: "55px" }}
            />{" "} */}
            Copyright © Kitty Meme Coin 2024. All rights reserved.
          </center>
        </div>
      </footer>

      <Link to="#" className="metaverse_fn_totop">
        <span className="icon"></span>
        <span className="arrow"></span>
        <span className="rocket"></span>
      </Link>
      <ZigZag />
    </>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Web3 from "web3";
import Carousal from "../3D_carousal/Carousal";
import Card_Crousal from "../card_crousal/Card_Crousal";
import SlicedSlider from "../Sliced_Slider/SlicedSlider";
import ZigZag from "../zigzag/ZigZag";
import { HashLink as Link } from "react-router-hash-link";
import Slider from "../full_slider/Slider";
import { ReactComponent as ReactLogo } from "../../assets/svg/social/twitter-1.svg";
import { ReactComponent as ReactLogo1 } from "../../assets/svg/social/facebook-1.svg";
import { ReactComponent as ReactLogo2 } from "../../assets/svg/social/instagram-1.svg";
import { ReactComponent as ReactLogo3 } from "../../assets/svg/social/pinterest-1.svg";
import { ReactComponent as ReactLogo4 } from "../../assets/svg/social/behance-1.svg";
import { ReactComponent as ReactLogo5 } from "../../assets/svg/search.svg";
import { ReactComponent as ReactLogo6 } from "../../assets/svg/down.svg";
import { ReactComponent as ReactLogo7 } from "../../assets/svg/down.svg";
import { ReactComponent as ReactLogo8 } from "../../assets/svg/social/telegram.svg";
import { ReactComponent as ReactLogo9 } from "../../assets/svg/social/reddit.svg";
import Logos from "../../assets/new/logo.png";
import roundLogo from "../../assets/new/logo.png";
import pdfWhitepaper from "../../assets/files/whitepaper.pdf";

const Header = () => {
  const [account, setAccount] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const loction = useLocation();
  const path = loction.pathname;

  const [active, setActive] = useState({ SlicedSlider: true });
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const gotopage = () => {
    setShow(false);
  };

  const checkAuthentication = async () => {
    // if (window.ethereum) {
    //   try {
    //     const web3 = new Web3(window.ethereum);
    //     const accounts = await web3.eth.getAccounts();

    //     if (accounts.length > 0) {
    //       const isAuthenticated = localStorage.getItem("authenticated");
    //       if (isAuthenticated) {
    //         setAccount(accounts[0]);
    //         setAuthenticated(true);
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error checking Metamask authentication:", error);
    //   }
    // } else {
    //   console.error("Metamask not found");
    // }
  };

  const logout = () => {
    setAccount(null);
    setAuthenticated(false);
    localStorage.removeItem("authenticated");
    window.location.reload();
  };

  // useEffect(() => {
  //   checkAuthentication();
  // }, []);

  return (
    <>
      <div className="metaverse_fn_mobnav">
        <div className="mob_top">
          <div className="logo" onClick={() => alert("Clicked")}>
            <Link to="/">
              <img src={Logos} style={{ height: "60px" }} alt="" />
              <span style={{ color: "#fff" }}>Kitty Meme Coin</span>
            </Link>
          </div>
          <div className="triggers">
          <a target='_blank' href='https://www.pancakeswap.finance/swap?outputCurrency=0x47C9dAE1246ec7bf1738e4E72F404A4E3B1EbecC' className="connect_btn">
            {/* <Link to="#" className="connect_btn"> */}
              <span className="text risque">
                <img src={roundLogo} alt="" className="buy-icon" /> Buy
                <span> Kitty</span>
              </span>
            </a>
            <Link className="trigger trigger_menu" to="#">
              <span></span>
            </Link>
            {/*<Link className="trigger trigger_bar" to="#">*/}
            {/*  <span></span>*/}
            {/*</Link>*/}
          </div>
        </div>
        <div className="mob_bot">
          <ul>
            {/*
            <li>
              <Link className="creative_link risque" to="/">
                Not just a meme
              </Link>
            </li>
            */}
            <li>
              <Link className="creative_link risque" to="#about">
                About
              </Link>
            </li>
            <li>
              <Link className="creative_link risque" to="#prosperity">
                Vision
              </Link>
            </li>
            <li>
              <Link className="creative_link risque" to="#video">
                Tokenomics
              </Link>
            </li>
            <li>
              <Link className="creative_link risque" to="#roadmap">
                Roadmap
              </Link>
            </li>
            <li>
              <a
                className="creative_link risque"
                href="https://coinsult.net/projects/kitty-meme-coin/"
                target="_blank"
              >
                Audit Report
              </a>
            </li>
            <li>
              <Link className="creative_link risque" to="#contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <header id="header">
        <div className="header done">
          <div className="header_in">
            <div className="logo">
              <Link className="has_text" to="/">
                <img src={Logos} style={{ height: "60px" }} alt="" />
                <span style={{ position: "relative; top: 10px" }}>
                  <span className="upper-text">Kitty Meme</span>
                  <br />
                  <span className="lower-text">Coin</span>
                </span>
              </Link>
            </div>
            <div className="nav">
              <ul>
                {/*
                <li className="menu-item-has-children">
                  <Link className="gradient_link menu_btn risque" to="/">
                    <span>Not just a meme</span>
                  </Link>
                </li>
                */}
                <li>
                  <Link className="gradient_link menu_btn risque" to="#about">
                    <span>About</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="gradient_link menu_btn risque"
                    to="#prosperity"
                  >
                    <span>Vision</span>
                  </Link>
                </li>
                <li>
                  <Link className="gradient_link menu_btn risque" to="#video">
                    <span>Tokenomics</span>
                  </Link>
                </li>
                <li>
                  <Link className="gradient_link menu_btn risque" to="#roadmap">
                    <span>Roadmap</span>
                  </Link>
                </li>
                <li>
                  <a
                    href="https://coinsult.net/projects/kitty-meme-coin/"
                    target="_blank"
                    className="gradient_link menu_btn risque"
                  >
                    <span>Audit Report</span>
                  </a>
                </li>
                <li>
                  <Link className="gradient_link menu_btn risque" to="#contact">
                    <span>Contact</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="helpful">
              {account ? (
                <div>
                  <a
                    href="#"
                    className="metaverse_fn_button full"
                    onClick={logout}
                  >
                    <span className="text">
                      <img src={roundLogo} alt="" className="buy-icon" />{" "}
                      {account.substring(0, 6)}...{account.substring(38)}
                    </span>
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    target='_blank'
                    href='https://www.pancakeswap.finance/swap?outputCurrency=0x47C9dAE1246ec7bf1738e4E72F404A4E3B1EbecC'
                    className="metaverse_fn_button full connect_btn_news"
                  >
                    <span className="text">
                      <img src={roundLogo} alt="" className="buy-icon" /> Buy
                      Kitty
                    </span>
                  </a>
                </div>
              )}

              {/*<Link to="#" className="connect_btn">*/}
              {/*  <span className="text risque">*/}
              {/*    <img src={roundLogo} alt="" className="buy-icon"/> Buy<span> Kitty</span>*/}
              {/*  </span>*/}
              {/*</Link>*/}
              {/*<Link to="#" className="trigger">*/}
              {/*  <span></span>*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
        <div className="header">
          <div className="header_in">
            <div className="logo">
              <Link className="has_text" to="/">
                <img src={Logos} style={{ height: "60px" }} alt="" />
                <span style={{ position: "relative; top: 10px" }}>
                  <span className="upper-text">Kitty Meme</span>
                  <br />
                  <span className="lower-text">Coin</span>
                </span>
              </Link>
            </div>
            <div className="nav">
              <ul>
                {/*
                <li className="menu-item-has-children">
                  <Link className="gradient_link menu_btn risque" smooth to="/">
                    <span>Not just a meme</span>
                  </Link>
                </li>
                */}
                <li>
                  <Link
                    className="gradient_link menu_btn risque"
                    smooth
                    to="/#about"
                  >
                    <span>About</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="gradient_link menu_btn risque"
                    smooth
                    to="/#prosperity"
                  >
                    <span>Vision</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="gradient_link menu_btn risque"
                    smooth
                    to="/#video"
                  >
                    <span>Tokenomics</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="gradient_link menu_btn risque"
                    smooth
                    to="/#roadmap"
                  >
                    <span>Roadmap</span>
                  </Link>
                </li>
                <li>
                  <a
                    className="gradient_link menu_btn risque"
                    href="https://coinsult.net/projects/kitty-meme-coin/"
                    target="_blank"
                  >
                    <span>Audit Report</span>
                  </a>
                </li>
                <li>
                  <Link
                    className="gradient_link menu_btn risque"
                    smooth
                    to="/#contact"
                  >
                    <span>Contact</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="helpful">
              {account ? (
                <div>
                  <a
                    href="#"
                    className="metaverse_fn_button full"
                    onClick={logout}
                  >
                    <span className="text">
                      <img src={roundLogo} alt="" className="buy-icon" />{" "}
                      {account.substring(0, 6)}...{account.substring(38)}
                    </span>
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    target='_blank'
                    href='https://www.pancakeswap.finance/swap?outputCurrency=0x47C9dAE1246ec7bf1738e4E72F404A4E3B1EbecC'
                    className="metaverse_fn_button full connect_btn_news"
                  >
                    <span className="text">
                      <img src={roundLogo} alt="" className="buy-icon" /> Buy
                      Kitty
                    </span>
                  </a>
                </div>
              )}

              {/*<Link to="#" className="connect_btn">*/}
              {/*  <span className="text risque">*/}
              {/*    <img src={roundLogo} alt="" className="buy-icon"/> Buy<span> Kitty</span>*/}
              {/*  </span>*/}
              {/*</Link>*/}
              {/*<Link*/}
              {/*  to="#"*/}
              {/*  className="trigger "*/}
              {/*  onClick={() => {*/}
              {/*    setShow(true);*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <span></span>*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
      </header>

      {path === "/" && (
        <>
          <SlicedSlider hide={active.SlicedSlider} />
          <Slider hide={active.Slider} />
          <Carousal hide={active.CardsCarousel} />
          {active.dCarousel && <Card_Crousal />}

          {active.ZigZagCarousel && <ZigZag hide={active.ZigZagCarousel} />}
        </>
      )}
      {/* --------------------------------------------------rightnaviget function------------------ */}

      <div
        className={`metaverse_fn_rightnav_closer ${show ? "active" : "ready"}`}
        onClick={() => setShow(false)}
      ></div>
      <div className={`metaverse_fn_rightnav ${show ? "active" : "ready"}`}>
        <Link
          to="#"
          className="fn__closer replaced-svg"
          onClick={() => setShow(false)}
        >
          <img
            src="assets/svg/cancel.svg"
            alt=""
            className="fn__svg replaced-svg"
          />
        </Link>
        <div className="navbox">
          <div className="list_holder">
            <div className="list">
              <a href="https://opensea.io/" className="" target="_blank">
                <span className="text">
                  <img src={roundLogo} alt="" style={{ height: "60px" }} />
                </span>
              </a>
              {/*<a*/}
              {/*  href="https://opensea.io/"*/}
              {/*  className="metaverse_fn_button has_icon"*/}
              {/*  target="_blank"*/}
              {/*>*/}
              {/*  <span className="text">*/}
              {/*    <img src="assets/img/market/opensea.png" alt="" />*/}
              {/*    <span>Opensea</span>*/}
              {/*  </span>*/}
              {/*</a>*/}
              {/*<a*/}
              {/*  href="https://discord.com/"*/}
              {/*  className="metaverse_fn_button has_icon"*/}
              {/*  target="_blank"*/}
              {/*>*/}
              {/*  <span className="text">*/}
              {/*    <img src="assets/img/market/discord.png" alt="" />*/}
              {/*    <span>Discord</span>*/}
              {/*  </span>*/}
              {/*</a>*/}
            </div>
          </div>
          <div className="nav_holder">
            <span className="icon">
              <ReactLogo6 className="fn__svg replaced-svg" />
            </span>

            <ul>
              {/*<li>*/}
              {/*  <Link to="/">*/}
              {/*    <span className="creative_link">*/}
              {/*      Home*/}
              {/*      */}
              {/*    </span>*/}
              {/*  </Link>*/}
              {/* */}
              {/*</li>*/}
              <li>
                <Link
                  to="/"
                  // className="closeit "
                  // onClick={() => {
                  //   gotopage();
                  // }}
                >
                  <span className="creative_link">About</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/whitepaper"
                  onClick={() => {
                    gotopage();
                  }}
                >
                  <span className="creative_link">Whitepaper</span>
                </Link>
              </li>
              {/*<li>*/}
              {/*  <Link to="#">*/}
              {/*    <span className="creative_link">*/}
              {/*      Team*/}
              {/*     */}
              {/*    </span>*/}
              {/*  </Link>*/}
              {/*  */}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link*/}
              {/*    to="/blogs"*/}
              {/*    onClick={() => {*/}
              {/*      gotopage();*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <span className="creative_link">Blogs</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className="info_holder">
            {/*<div className="search_holder">*/}
            {/*  <input type="text" placeholder="Search here..." />*/}
            {/*  <Link to="#">*/}
            {/*    <ReactLogo5 className="fn__svg replaced-svg" />*/}
            {/*  </Link>*/}
            {/*</div>*/}
            <div className="copyright">
              <p>Copyright © Kitty Meme Coin 2024. All rights reserved.</p>
            </div>
            <div className="social_icons">
              <ul>
                <li>
                  <a
                    href="https://twitter.com/kittymemecoin?t=mpSMhRn24ta6gj8y-puYag&s=08"
                    target="_blank"
                  >
                    <ReactLogo className="fn__svg replaced-svg" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/Kittymemecoin?mibextid=ZbWKwL"
                    target="_blank"
                  >
                    <ReactLogo1 className="fn__svg replaced-svg" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/kittymemecoin/"
                    target="_blank"
                  >
                    <ReactLogo2 className="fn__svg replaced-svg" />
                  </a>
                </li>
                {/* <li>
                  <a href="http://t.me/kitty_memecoin" target="_blank">
                    <ReactLogo8 className="fn__svg replaced-svg" />
                  </a>
                </li> */}
                {/*<li>*/}
                {/*  <a href="https://www.reddit.com" target="_blank">*/}
                {/*    <ReactLogo9 className="fn__svg replaced-svg" />*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <a href="https://www.pinterest.com">*/}
                {/*    <ReactLogo3 className="fn__svg replaced-svg" />*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <a href="https://www.behance.net">*/}
                {/*    <ReactLogo4 className="fn__svg replaced-svg" />*/}
                {/*  </a>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {show && <div>dfd</div>}
    </>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';

const Preloader = () => {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Add the class after the screen has loaded
    setLoaded(true);

    // Remove the class when the component is reloaded
    return () => {
      setLoaded(false);
    };
  }, []);

  return (
    <div className={loaded ? 'metaverse_fn_preloader ready' : 'metaverse_fn_preloader'}>
      <div className="multi-spinner-container">
        <div className="multi-spinner">
          <div className="multi-spinner">
            <div className="multi-spinner">
              <div className="multi-spinner">
                <div className="multi-spinner">
                  <div className="multi-spinner"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;

import React, { Children } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ReactLogo } from "../../assets/svg/play.svg";
import { ReactComponent as ReactLogo1 } from "../../assets/svg/mountain.svg";
import { ReactComponent as ReactLogo2 } from "../../assets/svg/down.svg";
import d1 from "../../assets/img/slider/1.png";
import d2 from "../../assets/img/slider/2.png";
import d3 from "../../assets/img/slider/3.png";
import d4 from "../../assets/img/slider/4.png";
import d5 from "../../assets/img/slider/5.png";
import d6 from "../../assets/img/slider/6.png";
import d7 from "../../assets/img/slider/7.png";
import { useRef } from "react";

const Carousal = ({ hide }) => {
  useEffect(() => {
    slider();
  }, []);
  const sliderDo = (sliderTop, sliderBottom, activeIndex) => {
    let Children = document.getElementsByName("slli");
    let topLength = Children.length;
    if (activeIndex > topLength) {
      activeIndex -= topLength;
    }
    let indexPrev = activeIndex - 1;
    let indexPrev2 = activeIndex - 2;
    let indexNext = activeIndex + 1;
    let indexNext2 = activeIndex + 2;
    if (indexPrev > topLength) {
      indexPrev -= topLength;
    }
    if (indexPrev2 > topLength) {
      indexPrev2 -= topLength;
    }
    if (indexNext > topLength) {
      indexNext -= topLength;
    }
    if (indexNext2 > topLength) {
      indexNext2 -= topLength;
    }
    if (indexPrev < 1) {
      indexPrev += topLength;
    }
    if (indexPrev2 < 1) {
      indexPrev2 += topLength;
    }
    if (activeIndex < 1) {
      activeIndex += topLength;
    }
    if (indexNext < 1) {
      indexNext += topLength;
    }
    if (indexNext2 < 1) {
      indexNext2 += topLength;
    }
    for (let i = 0; i < topLength; i++) {
      Children[i].classList.remove("prev", "prev2", "active", "next", "next2");
    }
    document.getElementById("li" + indexPrev2).classList.add("prev2");
    document.getElementById("li" + indexPrev).classList.add("prev");
    document.getElementById("li" + activeIndex).classList.add("active");
    document.getElementById("li" + indexNext).classList.add("next");
    document.getElementById("li" + indexNext2).classList.add("next2");
    return activeIndex;
  };
  const slider_slid = useRef();
  const slider = () => {
    let slider = slider_slid.current;
    let sliderTop = document.getElementsByClassName(".slider_top");
    let sliderBottom = document.getElementsByClassName(".slider_content");
    let activeIndex = 2;
    let speed = 6000; // milliseconds

    // init slider animation
    let myInterval = setInterval(function () {
      activeIndex++;
      activeIndex = sliderDo(sliderTop[0], sliderBottom[0], activeIndex);
    }, speed);
  };
  return (
    <>
      <section id="carousel_3d" className={!hide ? "hide" : ""}>
        <div className="container">
          <div className="fn_cs_welcome">
            <h3 className="maintitle">CyberBrokers Collection</h3>
            <div className="buttons">
              <Link to="#" className="metaverse_fn_button">
                <span className="text">Mint Now</span>
              </Link>
              <Link to="#" className="metaverse_fn_button white">
                <span className="text">Whitelist Now</span>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="fn_cs_slider"
          data-responsive="on"
          ref={slider_slid}
          style={{ height: "757px" }}
        >
          <div className="slider_top">
            <img src="assets/img/1x1.jpg" alt="" />
            <ul>
              <li className="prev" name="slli" id={`li${1}`} data-index="1">
                <div className="item">
                  <img src="assets/img/1x1.jpg" alt="" />
                  <div className="item_in" style={{ height: "520px" }}>
                    <div className="left__border"></div>
                    <div className="right__border"></div>
                    <div
                      className="img"
                      style={{ backgroundImage: `url(${d1})` }}
                    ></div>
                  </div>
                </div>
              </li>
              <li className="active" name="slli" id={`li${2}`} data-index="2">
                <div className="item">
                  <img src="assets/img/1x1.jpg" alt="" />
                  <div className="item_in" style={{ height: "520px" }}>
                    <div className="left__border"></div>
                    <div className="right__border"></div>
                    <div
                      className="img"
                      style={{ backgroundImage: `url(${d2})` }}
                    ></div>
                  </div>
                </div>
              </li>
              <li className="next" name="slli" id={`li${3}`} data-index="3">
                <div className="item">
                  <img src="assets/img/1x1.jpg" alt="" />
                  <div className="item_in" style={{ height: "520px" }}>
                    <div className="left__border"></div>
                    <div className="right__border"></div>
                    <div
                      className="img"
                      style={{ backgroundImage: `url(${d3})` }}
                    ></div>
                  </div>
                </div>
              </li>
              <li className="next2" name="slli" id={`li${4}`} data-index="4">
                <div className="item">
                  <img src="assets/img/1x1.jpg" alt="" />
                  <div className="item_in" style={{ height: "520px" }}>
                    <div className="left__border"></div>
                    <div className="right__border"></div>
                    <div
                      className="img"
                      style={{ backgroundImage: `url(${d4})` }}
                    ></div>
                  </div>
                </div>
              </li>
              <li data-index="5" name="slli" id={`li${5}`}>
                <div className="item has_video" style={{ height: "547px" }}>
                  <img src="assets/img/1x1.jpg" alt="" />
                  <a
                    className="popup-youtube metaverse_fn_videobutton"
                    href="https://www.youtube.com/watch?v=7e90gBu4pas"
                  >
                    <ReactLogo className="fn__svg replaced-svg" />
                  </a>
                  <div className="item_in" style={{ height: "520px" }}>
                    <div className="left__border"></div>
                    <div className="right__border"></div>
                    <div
                      className="img"
                      style={{ backgroundImage: `url(${d5})` }}
                    ></div>
                  </div>
                </div>
              </li>
              <li data-index="6" name="slli" id={`li${6}`}>
                <div className="item">
                  <img src="assets/img/1x1.jpg" alt="" />
                  <div className="item_in" style={{ height: "520px" }}>
                    <div className="left__border"></div>
                    <div className="right__border"></div>
                    <div
                      className="img"
                      style={{ backgroundImage: `url(${d6})` }}
                    ></div>
                  </div>
                </div>
              </li>
              <li className="prev2" data-index="7" id={`li${7}`} name="slli">
                <div className="item">
                  <img src="assets/img/1x1.jpg" alt="" />
                  <div className="item_in" style={{ height: "520px" }}>
                    <div className="left__border"></div>
                    <div className="right__border"></div>
                    <div
                      className="img"
                      style={{ backgroundImage: `url(${d7})` }}
                    ></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="slider_nav" style={{ marginTop: "480px" }}>
            <Link to="#" className="prev">
              <span className="icon">
                <ReactLogo2 alt="" className="fn__svg replaced-svg" />
              </span>
            </Link>
            <span className="dash"></span>
            <Link to="#" className="next">
              <span className="icon">
                <ReactLogo2 alt="" className="fn__svg replaced-svg" />
              </span>
            </Link>
          </div>
        </div>

        <div className="section_divider">
          <ReactLogo1 alt="" className="fn__svg replaced-svg" />
        </div>
      </section>
    </>
  );
};

export default Carousal;

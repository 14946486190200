import React from "react";
import { ReactComponent as ReactLogo } from "../../assets/svg/lock.svg";

const Protected = () => {
  return (
    <div>
      <div className="metaverse_fn_protected">
        <div className="container">
          <div className="message_holder">
            <span className="icon">
              <ReactLogo className="fn__svg replaced-svg" />
            </span>
            <h3 className="fn__maintitle" data-align="center">
              Protected Page
            </h3>
            <p>Please, enter the password to have access to this page.</p>
            <div className="container-custom">
              <form>
                <input
                  name="post_password"
                  type="password"
                  placeholder="Type here..."
                />
                <label className="metaverse_fn_button">
                  <span className="text">
                    <input
                      type="submit"
                      name="Submit"
                      className="button"
                      value="Authenticate"
                    />
                  </span>
                </label>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Protected;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ReactLogo } from "../../assets/svg/social/twitter-1.svg";
import { ReactComponent as ReactLogo1 } from "../../assets/svg/social/facebook-1.svg";
import { ReactComponent as ReactLogo2 } from "../../assets/svg/social/instagram-1.svg";
import { ReactComponent as ReactLogo3 } from "../../assets/svg/social/pinterest-1.svg";
import { ReactComponent as ReactLogo4 } from "../../assets/svg/social/behance-1.svg";
import axios from "axios";

const BlogSingle = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [massage, setMassage] = useState("");

  const handleSumbit = (e) => {
    const url = "http://localhost/metaverse/contact.php";
    let fData = new FormData();
    fData.append("name", name);
    fData.append("email", email);
    fData.append("massage", massage);

    axios
      .post(url, fData)
      .then((response) => {
        setName("");
        setEmail("");
        setMassage("");
      })
      .catch((error) => {});
  };
  return (
    <div
      className="metaverse_fn_page"
      style={{ paddingLeft: "145px", paddingRight: "145px" }}
    >
      <div className="metaverse_fn_blog_single">
        <div className="container">
          <div className="metaverse_fn_share">
            <h5 className="label">Share:</h5>
            <ul>
              <li>
                <a href="https://twitter.com">
                  <ReactLogo className="fn__svg replaced-svg" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=100091434393535&mibextid=ZbWKwL">
                  <ReactLogo1 className="fn__svg replaced-svg" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com">
                  <ReactLogo2 className="fn__svg replaced-svg" />
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com">
                  <ReactLogo3 className="fn__svg replaced-svg" />
                </a>
              </li>
              <li>
                <a href="https://www.behance.net">
                  <ReactLogo4 className="fn__svg replaced-svg" />
                </a>
              </li>
            </ul>
          </div>
          <div className="metaverse_fn_breadcrumbs">
            <p>
              <Link to="/">Home</Link>
              <span className="separator">/</span>
              <Link to="/blogs">Blog</Link>
              <span className="separator">/</span>
              <span className="current">
                Metaverse’s NFT Goes Public in Nod to Crypto Adoption
              </span>
            </p>
          </div>
          <div className="single_img">
            <img
              src="assets/img/blog/single.jpg"
              alt=""
              style={{ minWidth: "100%" }}
            />
          </div>
          <div className="metaverse_fn_minis">
            <div className="m_item">
              <Link to="#">June 01, 2022</Link>
            </div>
            <div className="m_item">
              <span>
                By <Link to="#">Admin</Link>
              </span>
            </div>
            <div className="m_item">
              <Link to="#comments">3 Comments</Link>
            </div>
          </div>

          <div className="metaverse_fn_wsidebar">
            <div className="sidebar_left">
              <div className="single_title">
                <h3 className="fn__maintitle" data-align="left">
                  MetaVerse’s NFT Goes Public In Nod To Crypto Adoption
                </h3>
                <div className="categories">
                  <Link to="#">Tokens</Link>, <Link to="#">NFTs</Link>,{" "}
                  <Link to="#">Crypto</Link>
                </div>
              </div>

              <div className="single_desc">
                <p className="colorp">
                  Suspendisse eu velit est. Cras nec vestibulum quam. Donec
                  tincidunt purus nec enim tincidunt, sit amet facilisis massa
                  laoreet. Integer mollis nec sapien eu lacinia. Nunc eu massa
                  dictum, vulputate neque ac, porta mauris. Sed sollicitudin
                  nisi augue, a gravida turpis elementum vel. Curabitur sagittis
                  quis diam et rhoncus. Nam pellentesque imperdiet aliquet. Sed
                  non ante malesuada, ultrices sem at, tempus libero.
                </p>
                <p className="colorp">
                  Duis eu lorem ut mauris pulvinar auctor. Maecenas et dapibus
                  orci, eleifend euismod justo. Quisque luctus turpis eu
                  tristique feugiat. Praesent ac magna feugiat, interdum lacus
                  ac, interdum dui. Pellentesque id quam quis enim malesuada
                  rutrum. Orci varius natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus. Nulla vel neque et ex
                  vestibulum elementum at sed lacus. Fusce imperdiet mauris ac
                  mi viverra viverra. Maecenas pharetra nibh non arcu finibus,
                  vel ultrices arcu porttitor. Quisque ac nisl id lectus
                  faucibus pulvinar nec ut nisl. Duis ultricies ut lacus sed
                  rutrum.
                </p>
                <p className="colorp">
                  Duis eu lorem ut mauris pulvinar auctor. Maecenas et dapibus
                  orci, eleifend euismod justo. Quisque luctus turpis eu
                  tristique feugiat. Praesent ac magna feugiat, interdum lacus
                  ac, interdum dui. Pellentesque id quam quis enim malesuada
                  rutrum. Orci varius natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus. Nulla vel neque et ex
                  vestibulum elementum at sed lacus. Fusce imperdiet mauris ac
                  mi viverra viverra. Maecenas pharetra nibh non arcu finibus,
                  vel ultrices arcu porttitor. Quisque ac nisl id lectus
                  faucibus pulvinar nec ut nisl. Duis ultricies ut lacus sed
                  rutrum.
                </p>
                <p className="colorp">
                  Suspendisse dignissim ultricies iaculis. Suspendisse ultrices
                  turpis mi, non tincidunt orci interdum tempor. Praesent
                  venenatis, lorem egestas consequat tempor, nunc neque
                  venenatis risus, vitae interdum ipsum ipsum eget purus. Proin
                  ac leo non est imperdiet commodo. Donec dictum augue ut odio
                  feugiat vulputate. Pellentesque ultricies augue in posuere
                  ornare. Pellentesque pretium non nunc ac sodales.
                </p>
                <p className="colorp">
                  Nam et malesuada ante, in convallis libero. Aenean
                  sollicitudin egestas ante, eget porttitor leo fringilla sit
                  amet. Nam cursus neque ligula, in egestas elit porttitor vel.
                  Vestibulum ultricies tempus orci a auctor. Curabitur sed
                  pretium lacus, eget cursus dui. Aliquam at ex sit amet urna
                  interdum dignissim vel non dolor. Pellentesque mi nibh,
                  sollicitudin at neque id, tincidunt molestie lorem. Nulla
                  facilisi. Phasellus viverra mi ut sapien efficitur, sit amet
                  suscipit lorem commodo. Interdum et malesuada fames ac ante
                  ipsum primis in faucibus.
                </p>
              </div>

              <div className="metaverse_fn_author_info item">
                <div className="left__border"></div>
                <div className="right__border"></div>
                <div className="item_in">
                  <div className="info_img">
                    <img src="assets/img/blog/author.jpg" alt="" />
                  </div>
                  <div className="info_desc">
                    <h3 className="fn_title">Josephine Sanchez</h3>
                    <p className="fn_desc colorp">
                      Josephine is a writer, editor, etc.-er who muses about
                      life, design and travel for friendship, food
                    </p>
                    <ul className="author_social">
                      <li>
                        <a href="https://twitter.com">
                          <ReactLogo className="fn__svg replaced-svg" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=100091434393535&mibextid=ZbWKwL">
                          <ReactLogo1 className="fn__svg replaced-svg" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com">
                          <ReactLogo2 className="fn__svg replaced-svg" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.pinterest.com">
                          <ReactLogo3 className="fn__svg replaced-svg" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.behance.net">
                          <ReactLogo4 className="fn__svg replaced-svg" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="metaverse_fn_tags">
                <div className="tag_item">
                  <span>
                    <span className="left"></span>
                    <span className="right"></span>
                    <span className="text">Tags:</span>
                  </span>
                </div>
                <div className="tag_item">
                  <Link to="#">
                    <span className="left"></span>
                    <span className="right"></span>
                    <span className="text">Tokens</span>
                  </Link>
                </div>
                <div className="tag_item">
                  <Link to="#">
                    <span className="left"></span>
                    <span className="right"></span>
                    <span className="text">Featured</span>
                  </Link>
                </div>
                <div className="tag_item">
                  <Link to="#">
                    <span className="left"></span>
                    <span className="right"></span>
                    <span className="text">Insights</span>
                  </Link>
                </div>
                <div className="tag_item">
                  <Link to="#">
                    <span className="left"></span>
                    <span className="right"></span>
                    <span className="text">NFT</span>
                  </Link>
                </div>
                <div className="tag_item">
                  <Link to="#">
                    <span className="left"></span>
                    <span className="right"></span>
                    <span className="text">Crypto</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="sidebar_right">
              <div className="widget widget-articles">
                <div className="wid-title">
                  <span className="text">Top Articles</span>
                  <span className="icon"></span>
                </div>

                <div className="metaverse_fn_widget_articles">
                  <ul>
                    <li>
                      <div className="item">
                        <Link to="#" className="full_link"></Link>
                        <h3 className="fn_title">
                          Use social media to jump-start your creative career.
                        </h3>
                        <p className="fn_date">
                          <span className="post_date">June 22, 2022</span>
                          <span className="comment_count">5 Comments</span>
                        </p>
                        <span className="count">1</span>
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <Link to="#" className="full_link"></Link>
                        <h3 className="fn_title">
                          Staying at the Knickerbocker Mansion in Big Bear
                        </h3>
                        <p className="fn_date">
                          <span className="post_date">June 22, 2022</span>
                          <span className="comment_count">3 Comments</span>
                        </p>
                        <span className="count">2</span>
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <Link to="#" className="full_link"></Link>
                        <h3 className="fn_title">
                          10 Places to Visit in California (That Aren’t Los
                          Angeles)
                        </h3>
                        <p className="fn_date">
                          <span className="post_date">June 22, 2022</span>
                          <span className="comment_count">2 Comments</span>
                        </p>
                        <span className="count">3</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="widget widget-custom-categories">
                <div className="wid-title">
                  <span className="text">Categories</span>
                  <span className="icon"></span>
                </div>
                <div
                  className="metaverse_fn_categories"
                  data-more="Show More"
                  data-less="Show Less"
                  data-count="4"
                >
                  <ul>
                    <li>
                      <Link to="#">
                        <span>Lifestyle</span>
                      </Link>
                      <span className="count">77</span>
                    </li>
                    <li>
                      <Link to="#">
                        <span>Travel</span>
                      </Link>
                      <span className="count">48</span>
                    </li>
                    <li>
                      <Link to="#">
                        <span>Clothing</span>
                      </Link>
                      <span className="count">31</span>
                    </li>
                    <li>
                      <Link to="#">
                        <span>Food</span>
                      </Link>
                      <span className="count">29</span>
                    </li>
                    <li>
                      <Link to="#">
                        <span>Relationships</span>
                      </Link>
                      <span className="count">33</span>
                    </li>
                    <li>
                      <Link to="#">
                        <span>Beauty</span>
                      </Link>
                      <span className="count">24</span>
                    </li>
                    <li>
                      <Link to="#">
                        <span>Interior</span>
                      </Link>
                      <span className="count">53</span>
                    </li>
                    <li>
                      <Link to="#">
                        <span>Health & Welless</span>
                      </Link>
                      <span className="count">42</span>
                    </li>
                  </ul>
                  <div className="clearfix"></div>
                </div>
              </div>

              <div className="widget widget-social">
                <div className="wid-title">
                  <span className="text">Subscribe &amp; Follow</span>
                  <span className="icon"></span>
                </div>
                <div className="metaverse_fn_widget_social">
                  <ul>
                    <li>
                      <a href="https://twitter.com">
                        <ReactLogo className="fn__svg replaced-svg" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100091434393535&mibextid=ZbWKwL">
                        <ReactLogo1 className="fn__svg replaced-svg" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com">
                        <ReactLogo2 className="fn__svg replaced-svg" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com">
                        <ReactLogo3 className="fn__svg replaced-svg" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.behance.net">
                        <ReactLogo4 className="fn__svg replaced-svg" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="metaverse_fn_pnb">
          <div className="container wide">
            <div className="pnb_wrapper">
              <div className="prev item">
                <Link to="#" className="full_link"></Link>
                <div className="item_in">
                  <div
                    className="img"
                    style={{ backgroundImage: `url(assets/img/blog/1.jpg)` }}
                  ></div>
                  <div className="desc">
                    <p className="fn_desc">
                      <span>Prev Post</span>
                    </p>
                    <h3 className="fn_title">
                      Bankman-Fried Would Like to Talk With Musk About Twitter
                    </h3>
                  </div>
                </div>
              </div>

              <div className="fn_trigger">
                <Link to="/blogssingle" className="full_link"></Link>
                <div className="icon">
                  <div className="icon_wrapper">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>

              <div className="next item">
                <Link to="#" className="full_link"></Link>
                <div className="item_in">
                  <div
                    className="img"
                    style={{ backgroundImage: `url(assets/img/blog/3.jpg)` }}
                  ></div>
                  <div className="desc">
                    <p className="fn_desc">
                      <span>Next Post</span>
                    </p>
                    <h3 className="fn_title">
                      Crypto-Focused Silvergate Jumps on Blowout Earnings Report
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="metaverse_fn_comments">
            <div className="comment-title">
              <h3 className="fn_title">3 Comments</h3>
            </div>

            <div className="comment-list">
              <ul className="list">
                <li className="comment">
                  <div className="comment-body">
                    <div className="comment-avatar">
                      <img src="assets/img/blog/comment-author1.jpg" alt="" />
                    </div>
                    <div className="comment-text-wrap">
                      <div className="comment-data">
                        <h3 className="author">Alex Brandon</h3>
                        <p className="date">May 9, 2022 at 9:03 pm</p>
                      </div>
                      <div className="comment-text">
                        <div className="desc">
                          <p className="colorp">
                            Nam et malesuada ante, in convallis libero. Aenean
                            sollicitudin egestas ante, eget porttitor leo
                            fringilla sit amet. Nam cursus neque ligula, in
                            egestas elit porttitor vel. Vestibulum ultricies
                            tempus orci a auctor.{" "}
                          </p>
                        </div>
                        <div className="fn_reply">
                          <Link to="#" className="reply">
                            <img
                              src="assets/svg/reply.svg"
                              alt=""
                              className="fn__svg replaced-svg"
                            />
                            <span>Reply</span>
                          </Link>
                          <Link to="#" className="edit">
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="comment">
                  <div className="comment-body">
                    <div className="comment-avatar">
                      <img src="assets/img/blog/comment-author2.jpg" alt="" />
                    </div>
                    <div className="comment-text-wrap">
                      <div className="comment-data">
                        <h3 className="author">Meera</h3>
                        <p className="date">May 9, 2022 at 9:03 pm</p>
                      </div>
                      <div className="comment-text">
                        <div className="desc">
                          <p className="colorp">
                            Proin ac leo non est imperdiet commodo. Donec dictum
                            augue ut odio feugiat vulputate. Pellentesque
                            ultricies augue in posuere ornare.{" "}
                          </p>
                        </div>
                        <div className="fn_reply">
                          <Link to="#" className="reply">
                            <img
                              src="assets/svg/reply.svg"
                              alt=""
                              className="fn__svg replaced-svg"
                            />
                            <span>Reply</span>
                          </Link>
                          <Link to="#" className="edit">
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="comment">
                  <div className="comment-body">
                    <div className="comment-avatar">
                      <img src="assets/img/blog/comment-author3.jpg" alt="" />
                    </div>
                    <div className="comment-text-wrap">
                      <div className="comment-data">
                        <h3 className="author">Rachel Perry</h3>
                        <p className="date">May 9, 2022 at 9:03 pm</p>
                      </div>
                      <div className="comment-text">
                        <div className="desc">
                          <p className="colorp">
                            Cras sed velit vitae velit tincidunt venenatis nec
                            in felis. Proin blandit elementum risus, sed mattis
                            dui rhoncus vel. Sed maximus mauris fringilla purus
                            auctor, eget convallis nulla facilisis.
                          </p>
                          <p className="colorp">
                            Morbi et lacinia ligula, id pharetra lorem.
                            Phasellus posuere nibh et sem dapibus, ut eleifend
                            ipsum eleifend. Pellentesque vel nibh aliquam leo
                            efficitur bibendum vel sed mi. Ut pulvinar rutrum
                            ante, at ultrices leo tincidunt eget.
                          </p>
                          <p className="colorp">
                            Suspendisse eget dui ac turpis consectetur
                            sollicitudin. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit.
                          </p>
                        </div>
                        <div className="fn_reply">
                          <Link to="#" className="reply">
                            <img
                              src="assets/svg/reply.svg"
                              alt=""
                              className="fn__svg replaced-svg"
                            />
                            <span>Reply</span>
                          </Link>
                          <Link to="#" className="edit">
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="comment-respond">
              <div className="respond-title">
                <h3 className="fn_title">Leave a reply</h3>
              </div>
              <div className="respond-log">
                <p>
                  Logged in as admin. <Link to="#">Log out?</Link>
                </p>
              </div>
              <form className="comment-form">
                <ul className="input-items">
                  <li className="input-item">
                    <div className="input_item">
                      <textarea
                        name="comment"
                        placeholder="Your Comment"
                        value={massage}
                        onChange={(e) => {
                          setMassage(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </li>
                  <li className="input-item half-item">
                    <div className="input_item">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </li>
                  <li className="input-item half-item">
                    <div className="input_item">
                      <input
                        type="text"
                        name="email"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </li>
                </ul>
                <div className="clearfix"></div>
                <Link
                  to="#"
                  className="metaverse_fn_button"
                  onClick={() => handleSumbit()}
                >
                  <span className="text">Send Message</span>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSingle;

import React from "react";
import { ReactComponent as ReactLogo } from "../../assets/svg/browser.svg";

const NoResutl = () => {
  return (
    <div className="metaverse_fn_404">
      <div className="container">
        <div className="message_holder">
          <span className="icon">
            <ReactLogo className="fn__svg replaced-svg" />
          </span>
          <h4
            className="fn_animated_text"
            data-color="var(--mc2)"
            data-to-color="#fff"
            data-align="center"
          >
            Nothing Found
          </h4>
          <p>
            Sorry, no content matched your criteria. Try searching something
            else.
          </p>
          <div className="container-custom">
            <form>
              <input name="s" type="text" placeholder="Search here..." />
              <label className="metaverse_fn_button">
                <span className="text">
                  <input
                    type="submit"
                    name="Submit"
                    className="button"
                    value="Search Now"
                  />
                </span>
              </label>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoResutl;

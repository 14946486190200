import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import cats from "../../assets/new/kitty-mene.png";
import { ReactComponent as ReactLogo } from "../../assets/svg/mountain.svg";
import { ReactComponent as ReactLogo5 } from "../../assets/svg/checked.svg";
import { ReactComponent as ReactLogoT } from "../../assets/svg/social/x.svg";
import { ReactComponent as ReactLogo1 } from "../../assets/svg/social/facebook-1.svg";
import { ReactComponent as ReactLogo2 } from "../../assets/svg/social/instagram-1.svg";
import { ReactComponent as ReactLogo3 } from "../../assets/svg/social/pinterest-1.svg";
import { ReactComponent as ReactLogo4 } from "../../assets/svg/social/behance-1.svg";
import { ReactComponent as ReactLogoS } from "../../assets/svg/search.svg";
import { ReactComponent as ReactLogo6 } from "../../assets/svg/down.svg";
import { ReactComponent as ReactLogo7 } from "../../assets/svg/down.svg";
import { ReactComponent as ReactLogo8 } from "../../assets/svg/social/telegram.svg";
import { ReactComponent as ReactLogo9 } from "../../assets/svg/social/reddit.svg";
import { ReactComponent as ReactLogo10 } from "../../assets/svg/social/discord.svg";
import newCat from "../../assets/img/kitty-banner.png";
// import newCat from "../../assets/img/kitty-swag.png";
import roundLogo from "../../assets/new/logo.png";
import { Waypoint } from "react-waypoint";
import pdfWhitepaper from "../../assets/files/whitepaper.pdf";
import solidProofAudit from "../../assets/files/audit-solidproof.pdf";
import logoShieldSloganWhite from "../../assets/img/logo_shield_text_white.svg";
import logoCoinsult from "../../assets/img/audit_by_coinsult.png";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const SlicedSlider = ({ hide }) => {
  const swiperRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const f1 = useRef();
  const f2 = useRef();
  const f3 = useRef();
  const f4 = useRef();
  const f5 = useRef();
  const f6 = useRef();
  const f7 = useRef();
  const f8 = useRef();
  const f9 = useRef();
  const f10 = useRef();
  const f11 = useRef();
  const f12 = useRef();
  const f13 = useRef();
  const f14 = useRef();
  const f15 = useRef();
  const f16 = useRef();
  const f17 = useRef();
  const f18 = useRef();
  const f19 = useRef();
  const f20 = useRef();
  const f21 = useRef();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => alert("Address copied"));
  };

  const elmn = (elnum) => {
    let body = "body";
    let element;
    if (elnum === 1) {
      element = f1.current;
    } else if (elnum === 2) {
      element = f2.current;
    } else if (elnum === 3) {
      element = f3.current;
    } else if (elnum === 4) {
      element = f4.current;
    } else if (elnum === 5) {
      element = f5.current;
    } else if (elnum === 6) {
      element = f6.current;
    } else if (elnum === 7) {
      element = f7.current;
    } else if (elnum === 8) {
      element = f8.current;
    } else if (elnum === 9) {
      element = f9.current;
    } else if (elnum === 10) {
      element = f10.current;
    } else if (elnum === 11) {
      element = f11.current;
    } else if (elnum === 12) {
      element = f12.current;
    } else if (elnum === 13) {
      element = f13.current;
    } else if (elnum === 14) {
      element = f14.current;
    } else if (elnum === 15) {
      element = f15.current;
    } else if (elnum === 16) {
      element = f16.current;
    } else if (elnum === 17) {
      element = f17.current;
    } else if (elnum === 18) {
      element = f18.current;
    } else if (elnum === 19) {
      element = f19.current;
    } else if (elnum === 20) {
      element = f20.current;
    } else if (elnum === 21) {
      element = f21.current;
    }
    let words = element.innerHTML.split(" ");
    let speed = 0.03; // 0.05

    let color = "#fff";
    let toColor = "var(--mc2)";

    let escapedColor = color.replace(/[^a-zA-Z0-9]/g, "_");

    let word = "";
    let rate = 0;
    if (!element.classList.contains("stop")) {
      element.classList.add("stop");
      element.innerHTML = "";
      var html = "";
      for (let i = 0; i < words.length; i++) {
        word = "";
        let newword = words[i].split("");
        for (let j = 0; j < newword.length; j++) {
          word +=
            '<span class="letter" style="animation: FrenifyLetterAnimation_tc' +
            " .5s " +
            rate * speed +
            's  forwards">' +
            newword[j] +
            "</span>";
          rate++;
        }
        html += '<span class="word">' + word + "</span>&nbsp";
      }

      const template = document.createElement("style");
      template.innerHTML =
        "<style>@keyframes FrenifyLetterAnimation_tc" +
        " { 60% {transform: translate(20px, 0) scale(1); color: " +
        color +
        ";} 80% {transform: translate(20px, 0) scale(1); color: " +
        color +
        ";} 99% {transform: translate(0) scale(1.2);color: " +
        toColor +
        ";} 100% {transform: translate(0) scale(1); opacity: 1; color: " +
        color +
        ';} }.fn_animated_text[data-color="' +
        color +
        '"],.desc[data-color="' +
        color +
        '"],.fn_animated_text[data-color="' +
        color +
        '"] .letter{color: ' +
        color +
        ";}</style>";

      document.body.appendChild(template);
      setTimeout(function () {
        element.classList.add("done");
      }, 500 + rate * speed * 1000);
      element.innerHTML = html;
    }
  };
  return (
    <div className={!hide ? "hide" : ""}>
      <section id="firstHeader">
        <div className="mypadone">
          <div className="firstHeader">
            <div className="fn_cs_title ready">
              <h1
                className="title chiller text-capitalize"
                data-color="white"
                style={{ fontSize: "120px" }}
              >
                {/* <Waypoint onEnter={() => elmn(16)}>
                  <h1
                    className="fn_animated_text title chiller resfontone"
                    data-color="#c8dfff"
                    ref={f16}
                    style={{
                      justifyContent: "center",
                      textTransform: "initial",
                    }}
                  >
                    Kitty Meme Coin
                  </h1>
                </Waypoint> */}
                <img
                  src={newCat}
                  alt=""
                  className="home-cat"
                  style={{ marginBottom: "2rem" }}
                />{" "}
                <br />
                <Waypoint onEnter={() => elmn(2)}>
                  <span
                    className="fn_animated_text resfonttwo"
                    data-color="#c8dfff"
                    ref={f2}
                    style={{
                      textTransform: "initial",
                      color: "#c8dfff !important",
                      justifyContent: "center",
                      fontSize: "50px",
                    }}
                  >
                    Not Just A Meme But an Emotion !!
                  </span>
                </Waypoint>
                {/* <span style={{ fontSize: "50px" }}>Not Just A Meme But an Emotion !!</span> */}
              </h1>
            </div>
          </div>
        </div>
      </section>





      <section id="home">
        <div className="mypadone">
          <div className="container">
            <div className="hero_header">
              <div className="hero_left">
                <div className="description">
                  <Waypoint onEnter={() => elmn(1)}>
                    <h5
                      className="fn_animated_text title chiller  fn_animated_texttwo"
                      data-color="#fff"
                      ref={f1}
                      style={{ fontSize: "34px" }}
                    >
                      Join Kitty on Its Mission to Spread Positivity
                    </h5>
                  </Waypoint>
                  <p className="fn_animated_text">
                    Kitty Meme Coin is a powerful digital asset on a mission to
                    heal the world. Built on solid blockchain fundamentals, this
                    meme coin is here to revolutionize the way we see and use
                    cryptocurrency. Purr, meow, and roar your way to a kinder,
                    more compassionate planet with the one and only Kitty Meme
                    Coin.
                  </p>
                </div>
                <div className="buttons">
                  {/*<Link to="#" className="metaverse_fn_button">*/}
                  {/*  <span className="text">Buy Now</span>*/}
                  {/*</Link>*/}
                  {/*<Link to="#" className="metaverse_fn_button white">*/}
                  {/*  <span className="text">Whitepaper</span>*/}
                  {/*</Link>*/}

                  <a
                    href="https://discord.com/invite/wQzuBtcJEP"
                    target="_blank"
                    className="text-white"
                  >
                    <ReactLogo10 className="fn__svg replaced-svg home__svg" />
                  </a>
                  <a
                    href="https://www.facebook.com/Kittymemecoin?mibextid=ZbWKwL"
                    target="_blank"
                    className="text-white"
                  >
                    <ReactLogo1 className="fn__svg replaced-svg home__svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/kittymemecoin/"
                    target="_blank"
                    className="text-white"
                  >
                    <ReactLogo2 className="fn__svg replaced-svg home__svg" />
                  </a>
                  <a href="https://t.me/kitty_memecoin" target="_blank" className="text-white">
                    <ReactLogo8 className="fn__svg replaced-svg home__svg" />
                  </a>
                  <a
                    href="https://twitter.com/kittymemecoin?t=hoPHtIaZ2mPQjxNHANicyA&s=08"
                    target="_blank"
                    className="text-white"
                  >
                    <ReactLogoT className="fn__svg replaced-svg home__svg" />
                  </a>

                </div>

                <div className="module-wrap">
                  <select className="form-select form-control bsc-select">
                    <option value="0x47c9dae1246ec7bf1738e4e72f404a4e3b1ebecc">
                      BSC BEP20
                    </option>
                  </select>
                  <div className="code-wrap" id="module-code">
                    0x47c9dae1246ec7bf1738e4e72f404a4e3b1ebecc
                  </div>
                  <button
                    className="bg-transparent border-0"
                    onClick={() =>
                      copyToClipboard(
                        "0x47c9dae1246ec7bf1738e4e72f404a4e3b1ebecc"
                      )
                    }
                  >
                    <svg
                      width="16"
                      height="19"
                      viewBox="0 0 16 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.40411 18.7583H11.8369C12.6119 18.7583 13.241 18.1293 13.241 17.3542V4.16289C13.241 3.38784 12.6119 2.75879 11.8369 2.75879H1.40411C0.629049 2.75879 0 3.38784 0 4.16289V17.3542C0 18.1293 0.629049 18.7583 1.40411 18.7583ZM1.10354 4.1626C1.10354 3.99712 1.23862 3.86184 1.4043 3.86184H11.8371C12.0025 3.86184 12.1378 3.99692 12.1378 4.1626V17.3539C12.1378 17.5194 12.0027 17.6547 11.8371 17.6547L1.4038 17.6545C1.23832 17.6545 1.10305 17.5194 1.10305 17.3537L1.10354 4.1626Z"
                        fill="white"
                      ></path>
                      <path
                        d="M14.5961 2.48044e-05H4.16338C3.38833 2.48044e-05 2.75928 0.629074 2.75928 1.40413C2.75928 1.70758 3.0075 1.95579 3.31094 1.95579C3.61439 1.95579 3.8626 1.70757 3.8626 1.40413C3.8626 1.23865 3.99768 1.10337 4.16336 1.10337H14.5961C14.7616 1.10337 14.8969 1.23845 14.8969 1.40413V14.5955C14.8969 14.7609 14.7618 14.8962 14.5961 14.8962C14.2927 14.8962 14.0445 15.1444 14.0445 15.4479C14.0445 15.7513 14.2927 15.9995 14.5961 15.9995C15.3712 15.9995 16.0002 15.3705 16.0002 14.5954V1.40411C16.0002 0.62905 15.3712 0 14.5961 0L14.5961 2.48044e-05Z"
                        fill="white"
                      ></path>
                    </svg>
                  </button>
                </div>

                <div className="d-flex flex-row mt-3 flex-wrap justify-content-center">
                  <a
                    id="send_message"
                    href={`${pdfWhitepaper}`}
                    className="metaverse_fn_button full my-3"
                  >
                    <span className="text">
                      <img src={roundLogo} alt="" className="buy-icon" /> Read
                      Whitepaper
                    </span>
                  </a>
                  <a
                    href={solidProofAudit}
                    target="_blank"
                  >
                    <span className="text">
                      <img
                        src={logoShieldSloganWhite}
                        alt=""
                        style={{
                          height: "58px",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      />{" "}
                    </span>
                  </a>

                  <a
                    href="https://coinsult.net/projects/kitty-meme-coin/"
                    target="_blank"
                  >
                    <span className="text">
                      <img
                        src={logoCoinsult}
                        alt=""
                        style={{
                          height: "58px",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      />{" "}
                    </span>
                  </a>
                </div>
              </div>
              <div className="hero_right">
                <div className="fn_cs_glitch_slider">
                  <img src={cats} alt="cats" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section_divider">
          <ReactLogo alt="" className="fn__svg replaced-svg" />
        </div>


        

       

      </section>


    </div>
  );
};

export default SlicedSlider;

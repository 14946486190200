import React from "react";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Logo from "../../assets/img/slider/1.png";
import Logo1 from "../../assets/img/slider/2.png";
import Logo2 from "../../assets/img/slider/3.png";
import Logo3 from "../../assets/img/slider/4.png";
import Logo4 from "../../assets/img/slider/5.png";
import Logo5 from "../../assets/img/slider/6.png";
import Logo6 from "../../assets/img/slider/7.png";
// import Logo7 from "../../assets/img/slider/8.png";
import Logo8 from "../../assets/svg/down.svg";
import Logo9 from "../../assets/svg/down.svg";
import { ReactComponent as ReactLogo } from "../../assets/svg/mountain.svg";
import Img1x1 from "../../assets/img/1x1.jpg";

const ZigZag = ({ hide }) => {
  return (
    <>
      <section id="zigzag_carousel" className={!hide ? "hide" : ""}>
        <div className="container">
          <div className="fn_cs_welcome">
            <h3 className="maintitle">CyberBrokers Collection</h3>
            <div className="buttons">
              <Link to="#" className="metaverse_fn_button">
                <span className="text">Mint Now</span>
              </Link>
              <Link to="#" className="metaverse_fn_button white">
                <span className="text">Whitelist Now</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="fn_cs_zigzag">
          <Swiper
            className="swiper-container"
            loop
            slidesPerView={3}
            direction="horizontal"
            loopAdditionalSlides={10}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item odd">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in" style={{ height: "440px" }}>
                        <img src={Img1x1} alt="" />
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${Logo})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item even">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in" style={{ height: "440px" }}>
                        <img src={Img1x1} alt="" />
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${Logo1})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item odd">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in" style={{ height: "440px" }}>
                        <img src={Img1x1} alt="" />
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${Logo2})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="item even">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in" style={{ height: "440px" }}>
                        <img src={Img1x1} alt="" />
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${Logo3})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="item odd">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in" style={{ height: "440px" }}>
                        <img src={Img1x1} alt="" />
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${Logo4})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item even">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in" style={{ height: "440px" }}>
                        <img src={Img1x1} alt="" />
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${Logo5})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="item odd">
                      <div className="left__border"></div>
                      <div className="right__border"></div>
                      <div className="item_in" style={{ height: "440px" }}>
                        <img src={Img1x1} alt="" />
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${Logo6})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </div>
          </Swiper>
        </div>
        <div className="slider_nav">
          <Link to="#" className="prev">
            <span className="icon">
              <img src={`${Logo8}`} alt="" className="fn__svg replaced-svg" />
            </span>
          </Link>
          <span className="dash"></span>
          <Link to="#" className="next">
            <span className="icon">
              <img src={`${Logo9}`} alt="" className="fn__svg replaced-svg" />
            </span>
          </Link>
        </div>
        <div className="section_divider">
          <ReactLogo alt="" className="fn__svg replaced-svg" />
        </div>
      </section>
    </>
  );
};

export default ZigZag;
